export enum AlertIntegrationType {
    Email = "Email",
    Discord = "Discord",
};

export interface AlertIntegration {
    id: string;
    createdDate: string;
    createdUser: string;
    type: AlertIntegrationType;
    endpoint: string;
    active: boolean;
};
