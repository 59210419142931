import "./TestPage.scss";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import { Page } from "../../components/Page";
import { Link, useParams } from "react-router-dom";
import { DataCard } from "../../components/DataCard";
import { DonutChart } from "../../components/Charts/DonutChart";
import { useTest } from "../../hooks/useTests";
import { TestLogsTable } from "../../components/TestLogsTable";
import { Team } from "../../types";
import { useMemo } from "react";
import { timeSince } from "../../utils";
import { useProjects } from "../../hooks/useProjects";

interface TestPageProps {
    currentTeam: Team;
}

export const TestPage = ({ currentTeam }: TestPageProps) => {
    const params = useParams();
    const { data: projectData } = useProjects(currentTeam.id);
    const { data: testDisplayData, isLoading: isTestDisplayDataLoading } = useTest(currentTeam.id, projectData ? projectData[0].id : "", params.testId as any);

    const test = useMemo(() => {
        if (testDisplayData) return testDisplayData;
        return {
            id: "",
            name: "",
            schedule: "",
            createdUser: "",
            createdDate: "",
            lastRunDate: "",
            active: false,
            code: "",
            currentExecutionStartDate: "",
            latestExecutionId: "",
            latestExecution: {
                startDate: "",
                status: "Running"
            },
            metrics: {
                averageElapsedSeconds: -1,
                statusSummary: {
                    Error: 0,
                    Fail: 0,
                    Pass: 0,
                    Pending: 0,
                    Running: 0,
                },
            },
            dailyPassPct: "N/A",
            weeklyPassPct: "N/A",
        };
    }, [testDisplayData]);

    const isTestDataLoading = useMemo(() => {
        return isTestDisplayDataLoading || !testDisplayData || test.metrics.averageElapsedSeconds === -1;
    }, [isTestDisplayDataLoading, testDisplayData]);

    const areCardsLoading = useMemo(() => {
        return isTestDataLoading || test.metrics.averageElapsedSeconds === -1;
    }, [isTestDataLoading]);

    const CtaButton = () => {
        return (
            <Link to={`/${currentTeam.id}/edit-test/${params.testId}`} data-testid="edit-test-link">
                <Button variant="contained" color="secondary">
                    <Typography variant="body1" color="white">Edit Test</Typography>
                </Button>
            </Link>
        );
    }

    const PassingTag = () => {
        return (
            <Box className="passing-tag">
                <Typography variant="body1" color="#497B46">
                    Passing
                </Typography>
            </Box>
        );
    }

    const FailingTag = () => {
        return (
            <Box className="failing-tag">
                <Typography variant="body1" color="#D92D20">
                    Failing
                </Typography>
            </Box>
        );
    }

    const NATag = () => {
        return (
            <Box className="failing-tag">
                <Typography variant="body1" color="#D92D20">
                    N/A
                </Typography>
            </Box>
        );
    }

    return (
        <Page
            className="individual-test-page"
            title={test?.name ? test?.name : ""}
            subheaderElement={
                !test.latestExecution || !test.latestExecution.status
                    ? <NATag />
                    : test.latestExecution && test.latestExecution.status != "Pass" && test.latestExecution.status != "Fail"
                        ? <Skeleton sx={{ width: "10%" }} />
                        : test.latestExecution && test.latestExecution.status === "Pass"
                            ? <PassingTag />
                            : <FailingTag />
            }
            button={<CtaButton />}
        >
            <Box className="test-data-summary">
                <DataCard title="Alerts" value={test.metrics.statusSummary.Fail.toString()} isLoading={areCardsLoading} color="#497B46" />
                <DataCard title="Last Run" value={timeSince(test.latestExecution && test.latestExecution.startDate ? test.latestExecution.startDate : "")} isLoading={areCardsLoading} color="#497B46" />
                <DataCard title="Avg. Run Time" value={`${Math.round(test.metrics.averageElapsedSeconds * 10) / 10}s`} isLoading={areCardsLoading} color="#497B46" />
            </Box>
            {
                isTestDataLoading ?
                    <Skeleton /> :
                    test.dailyPassPct === "N/A" ?
                        <></> :
                        <DonutChart className="donut-chart" passingRate={Number(test.dailyPassPct)} />}
            <TestLogsTable currentTeam={currentTeam} testId={params.testId as any} />
        </Page>
    );
}