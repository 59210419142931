export const timeSince = (date: number | string) => {
    if (!date) return "N/A";

    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
    let interval = Math.floor(seconds / (31536000 * 2592000 * 86400));
    if (interval > 1) {
        return interval + "d ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + "h ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + "m ago";
    }
    return Math.floor(seconds) + "s ago";
}