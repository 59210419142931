const recommendedSolutions = {
    "contract": "This error means that the contract you are interacting with at the end of the test differs from the address you told the test to expect. Double check that you have entered the correct address and that the interaction you're performing is correct for the address you entered.",
    "timeout": "This error is usually caused by using an incorrect selector/descriptor to locate an element, or messing up the prior step resulting in the desired element not being visible.\n\nTo debug, first check that the selector/descriptor is correct. If the test still times out, double check all previous steps.",
    "multiple-elements": "This error is caused by selectors/descriptors not being specific to one element.\n\nTo solve, be more specific about the element your wish to locate by appending '.first()' to the 'page.locator' call, or by using '>> nth=n' in the 'page.locator' call.",
    "other": ""
}

const parseErrorType = (testOutput: string): string => {
    if (testOutput.includes('Expected: "0x')) return "contract";
    if (testOutput.includes("Test timeout")) return "timeout";
    if (testOutput.includes("Error: strict mode violation:")) return "multiple-elements";
    return "other";
}

export const getRecommendedSolution = (testOutput: string): string => {
    const errorType = parseErrorType(testOutput);
    return recommendedSolutions[errorType as keyof typeof recommendedSolutions];
}
