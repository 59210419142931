import { useAuth0, User } from "@auth0/auth0-react";
import { QueryFunction, QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Test, TestExecution, TestInputCreate, TestInputUpdate } from "../types";
import { ExecutionsData } from "../types/ExecutionsData";
import { TestDisplayData } from "../types/TestDisplayData";
import { isSuccess } from "../utils";
import { allExecutionsQueryKey, executionsQueryKey } from "./useExecutions";
import { failingTestsNumberQueryKey } from "./useFailingTestsNumber";
import { authorizedDeleteRequest, authorizedGetRequest, authorizedPatchRequest, authorizedPostRequest, getApiAccessTokenParams, getApiEndpoint } from "./useFrontendApi";
import { passingTestsNumberQueryKey } from "./usePassingTestsNumber";
import { singleTestRunCountQueryKey, testRunCountQueryKey } from "./useTestRunCount";

export const getAllTests = async (accessToken: string, teamId: string, projectId: string) => {
    const response = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests`, accessToken);
    const tests: Test[] = response.data.records;
    const activeTests: Test[] = tests.filter(test => test.active);

    return activeTests;
}

// POST /teams/{teamId}/projects/{projectId}/tests
export const useCreateTest = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<Test, Error, TestInputCreate>(
        async (testCreation: TestInputCreate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedPostRequest(`teams/${testCreation.teamId}/projects/${testCreation.projectId}/tests`, testCreation.newTest, accessToken);
            return response.data.test as Test;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = testsQueryKey(user, inputs.teamId, inputs.projectId);
                await client.refetchQueries([keyToRefetch]);
            },
        }
    );
};

// GET /teams/{teamId}/projects/{projectId}/tests
export const testsQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useTests", user, teamId, projectId];
export const useTests = (teamId: string, projectId: string) => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<TestDisplayData[], Error>([testsQueryKey(user, teamId, projectId)], async () => {
        if (teamId === "" || projectId === "") return [];

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const dailyResponse = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests?sinceHours=${24}`, accessToken);
        const weeklyResponse = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests?sinceHours=${168}`, accessToken);
        if (weeklyResponse.status !== 200 || dailyResponse.status !== 200) throw new Error("Failed to get tests");

        const displayTests: TestDisplayData[] = [];
        for (let i = 0; i < dailyResponse.data.records.length; i++) {
            const dailyRecord = dailyResponse.data.records[i];
            const weeklyRecord = weeklyResponse.data.records[i];

            const hasDailyTestRun = dailyRecord.metrics.statusSummary.Pass + dailyRecord.metrics.statusSummary.Fail > 0;
            const hasWeeklyTestRun = weeklyRecord.metrics.statusSummary.Pass + weeklyRecord.metrics.statusSummary.Fail > 0;

            const dailyPassPct = hasDailyTestRun ?
                Math.floor(100 * dailyRecord.metrics.statusSummary.Pass / (dailyRecord.metrics.statusSummary.Pass + dailyRecord.metrics.statusSummary.Fail)) :
                "N/A";
            const weeklyPassPct = hasWeeklyTestRun ?
                Math.floor(100 * weeklyRecord.metrics.statusSummary.Pass / (weeklyRecord.metrics.statusSummary.Pass + weeklyRecord.metrics.statusSummary.Fail)) :
                "N/A";

            const testDisplayData: TestDisplayData = {
                ...weeklyRecord,
                dailyPassPct: dailyPassPct,
                weeklyPassPct: weeklyPassPct,
            };

            displayTests.push(testDisplayData);
        };

        return displayTests;
    },
    {
        enabled: teamId !== "" && projectId !== "",
        onSuccess: async (data) => {
            const keysToInvalidate = [
                allExecutionsQueryKey(user, teamId, projectId),
                passingTestsNumberQueryKey(user, teamId, projectId),
                failingTestsNumberQueryKey(user, teamId, projectId),
                testRunCountQueryKey(user, teamId, projectId),
            ];
            let promises = keysToInvalidate.map(key => client.invalidateQueries([key]));
            await Promise.all(promises);
        },
    }
    );
};

// GET /teams/{teamId}/projects/{projectId}/tests/{testId}
export const testQueryKey = (user?: User, teamId?: string, projectId?: string, testId?: string) => ["useTest", user, teamId, projectId, testId];
export const useTest = (teamId: string, projectId: string, testId: string) => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<TestDisplayData, Error>([testQueryKey(user, teamId, projectId, testId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        if (!testId) return {} as TestDisplayData;

        const dailyResponse = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}`, accessToken);
        const weeklyResponse = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}?sinceHours=${168}`, accessToken);
        if (weeklyResponse.status !== 200 || dailyResponse.status !== 200) throw new Error("Failed to get test");

        const dailyRecord = dailyResponse.data.record;
        const weeklyRecord = weeklyResponse.data.record;

        const hasDailyTestRun = dailyRecord.metrics.statusSummary.Pass + dailyRecord.metrics.statusSummary.Fail > 0;
        const hasWeeklyTestRun = weeklyRecord.metrics.statusSummary.Pass + weeklyRecord.metrics.statusSummary.Fail > 0;

        const dailyPassPct = hasDailyTestRun ?
                Math.floor(100 * dailyRecord.metrics.statusSummary.Pass / (dailyRecord.metrics.statusSummary.Pass + dailyRecord.metrics.statusSummary.Fail)) :
                "N/A";
        const weeklyPassPct = hasWeeklyTestRun ?
            Math.floor(100 * weeklyRecord.metrics.statusSummary.Pass / (weeklyRecord.metrics.statusSummary.Pass + weeklyRecord.metrics.statusSummary.Fail)) :
            "N/A";

        const test: TestDisplayData = {
            ...weeklyRecord,
            dailyPassPct: dailyPassPct,
            weeklyPassPct: weeklyPassPct,
        }

        if (!test.active) return {} as TestDisplayData;
        return test;
    },
    {
        enabled: teamId !== "" && projectId !== "" && testId !== "",
        onSuccess: async (data) => {
            const keysToInvalidate = [
                singleTestRunCountQueryKey(user, teamId, projectId, testId),
            ];
            let promises = keysToInvalidate.map(key => client.invalidateQueries([key]));
            await Promise.all(promises);
        }
    });
};

// export const testsDisplayDataQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useTestsDisplayData", user, teamId, projectId];
// export const useTestsDisplayData = (teamId: string, projectId: string) => {
//     const client = useQueryClient();
//     const { user } = useAuth0();

//     return useQuery<TestDisplayData[], Error>([testsDisplayDataQueryKey(user, teamId, projectId)], async () => {
//         const tests: any[] | undefined = client.getQueryData([testsQueryKey(user, teamId, projectId)]);
//         if (!tests) {
//             await client.refetchQueries([testsQueryKey(user, teamId, projectId)]);
//             return [];
//         }

//         const executionsData: any[] | undefined = client.getQueryData([allExecutionsQueryKey(user, teamId, projectId)]);
//         if (!executionsData) {
//             await client.refetchQueries([allExecutionsQueryKey(user, teamId, projectId)]);
//             return [];
//         }

//         if (tests.length == 0 || executionsData.length == 0) return [];

//         const testsDisplayData: TestDisplayData[] = tests.map(test => {
//             const executions = executionsData.filter(execution => execution.testId === test.id);
//             const dailyPassPct = getPassPct(executions, 1440);
//             const weeklyPassPct = getPassPct(executions, 10080);

//             console.log(test.executionStatusSummary);

//             return {
//                 id: test.id,
//                 name: test.name,
//                 schedule: test.schedule,
//                 createdUser: test.createdUser,
//                 createdDate: test.createdDate,
//                 lastRunDate: test.lastRunDate,
//                 active: test.active,
//                 code: test.code,
//                 currentExecutionStartDate: test.latestExecution.startDate,
//                 latestExecutionId: test.latestExecution.id,
//                 latestExecution: test.latestExecution,
//                 dailyPassPct: dailyPassPct,
//                 weeklyPassPct: weeklyPassPct,
//             }
//         });

//         return testsDisplayData;
//     });
// }

// export const testDisplayDataQueryKey = (user?: User, teamId?: string, projectId?: string, testId?: string) => ["useTestDisplayData", user, teamId, projectId, testId];
// export const useTestDisplayData = (teamId: string, projectId: string, testId: string) => {
//     const client = useQueryClient();
//     const { user } = useAuth0();

//     return useQuery<TestDisplayData, Error>([testDisplayDataQueryKey(user, teamId, projectId, testId)], async () => {
//         const tests: any[] | undefined = client.getQueryData([testsQueryKey(user, teamId, projectId)]);
//         if (!tests) {
//             return {} as TestDisplayData;
//         }

//         const executionsData: any[] | undefined = client.getQueryData([allExecutionsQueryKey(user, teamId, projectId)]);
//         if (!executionsData) {
//             await client.fetchQuery([allExecutionsQueryKey(user, teamId, projectId)]);
//             return {} as TestDisplayData;
//         }

//         const test = tests.filter(test => test.id === testId)[0];
//         if (!test) return {} as TestDisplayData;

//         const executions = executionsData.filter(execution => execution.testId === test.id);
//         const dailyPassPct = getPassPct(executions, 1440);
//         const weeklyPassPct = getPassPct(executions, 10080);

//         const testDisplayData: TestDisplayData = {
//             id: test.id,
//             name: test.name,
//             schedule: test.schedule,
//             createdUser: test.createdUser,
//             createdDate: test.createdDate,
//             lastRunDate: test.lastRunDate,
//             active: test.active,
//             code: test.code,
//             currentExecutionStartDate: test.latestExecution.startDate,
//             latestExecutionId: test.latestExecution.id,
//             latestExecution: test.latestExecution,
//             dailyPassPct: dailyPassPct,
//             weeklyPassPct: weeklyPassPct,
//         }

//         return testDisplayData;
//     });
// }

// PUT /teams/{teamId}/projects/{projectId}/tests/{testId}
export const useUpdateTest = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<Test, Error, TestInputUpdate>(
        async (testUpdate: TestInputUpdate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedPatchRequest(`teams/${testUpdate.teamId}/projects/${testUpdate.projectId}/tests/${testUpdate.testId}`, testUpdate.updatedTest , accessToken);
            return response.data.test as Test;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    testsQueryKey(user, inputs.teamId, inputs.projectId),
                    testQueryKey(user, inputs.teamId, inputs.projectId, inputs.testId)
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        }
    );
};

// DELETE /teams/{teamId}/projects/{projectId}/tests/{testId}
export const useDeleteTest = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<boolean, Error, any>(
        // TODO: Create a type for this
        async ({ teamId, projectId, testId }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedDeleteRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}`, accessToken);
            return response.data.success;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = testsQueryKey(user, inputs.teamId, inputs.projectId);
                await client.refetchQueries([keyToRefetch]);
            },
        }
    );
};
