import "./CreateAlerts.scss";

import { Box, Button, OutlinedInput, Switch, Typography } from "@mui/material";
import { Page } from "../../components/Page"
import { AlertModal } from "../../components/AlertModal";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useMemo, useState } from "react";
import { AlertSetupModal } from "../../components/AlertSetupModal/AlertSetupModal";
import { Team, Workspace } from "../../types";
import { useAlertIntegrations, useCreateAlertIntegration, useUpdateAlertIntegration } from "../../hooks/useAlertIntegration";
import { useAuth0 } from "@auth0/auth0-react";
import { AlertIntegrationInputCreate } from "../../types/AlertIntegrationInputCreate";
import { AlertIntegrationType } from "../../types/AlertIntegration";
import { AlertIntegrationInputUpdate } from "../../types/AlertIntegrationInputUpdate";
import { useProjects } from "../../hooks/useProjects";

interface CreateAlertProps {
    currentTeam: Team;
}

export const CreateAlerts = ({ currentTeam }: CreateAlertProps) => {
    const { user } = useAuth0();
    const { data: projectData } = useProjects(currentTeam.id);

    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [isDiscordSetupModalOpen, setIsDiscordSetupModalOpen] = useState(false);
    // const [isTelegramSetupModalOpen, setIsTelegramSetupModalOpen] = useState(false);

    const [isEmailEnabled, setIsEmailEnabled] = useState(false);

    const [emailEndpoint, setEmailEndpoint] = useState("");
    const [discordId, setDiscordId] = useState("");
    const [discordEndpoint, setDiscordEndpoint] = useState("");

    const { data: alertsData } = useAlertIntegrations(currentTeam.id, projectData ? projectData[0].id : "");

    const createAlertIntegrationMutation = useCreateAlertIntegration();
    const updateAlertIntegrationMutation = useUpdateAlertIntegration();

    useEffect(() => {
        if (!alertsData) return;
        if (alertsData.find(alert => (alert.type === "Email" && alert.createdUser === user?.sub))) {
            setEmailEndpoint(alertsData.find(alert => (alert.type === "Email" && alert.createdUser === user?.sub))!.endpoint);
        }
        if (alertsData.find(alert => alert.type === "Discord")) {
            setDiscordId(alertsData.find(alert => alert.type === "Discord")!.id);
            setDiscordEndpoint(alertsData.find(alert => alert.type === "Discord")!.endpoint);
        }
    }, [alertsData]);

    const alerts = useMemo(() => {
        if (!alertsData) return [];
        return alertsData;
    }, [alertsData]);

    const handleToggleEmail = () => {
        setIsEmailEnabled(!isEmailEnabled);
    };

    const handleSaveAlerts = async () => {
        if (!projectData) return;

        const alert = alerts.find(alert => (alert.type === "Email" && alert.createdUser === user?.sub));
        if (!alert && emailEndpoint != "") {
            const newEmailAlert: AlertIntegrationInputCreate = {
                teamId: currentTeam.id,
                projectId: projectData[0].id,
                newAlertIntegration: {
                    type: AlertIntegrationType.Email,
                    endpoint: emailEndpoint,
                    active: true
                },
            };

            createAlertIntegrationMutation.mutate(newEmailAlert);
        } else if (alert && emailEndpoint != "") {
            const updatedEmailAlert: AlertIntegrationInputUpdate = {
                teamId: currentTeam.id,
                projectId: projectData[0].id,
                alertIntegrationId: alert.id,
                endpoint: emailEndpoint
            };

            updateAlertIntegrationMutation.mutate(updatedEmailAlert);
        }

        setIsAlertModalOpen(false);
    }

    return (
        <>
            <Page
                className="create-alerts-page"
                title="Create Alerts"
                subheader="Configure how you want to receive alerts from GuardianUI."
            >
                <Box className="alerts-input-container">
                    <Typography className="alert-input-name" variant="body1">Email address</Typography>
                    <OutlinedInput
                        className="alert-input-box"
                        data-testid="email-input-box"
                        notched={false}
                        value={emailEndpoint}
                        onChange={(e) => setEmailEndpoint(e.target.value)}
                    />
                    { /*
                        <Switch
                            checked={isEmailEnabled}
                            onClick={handleToggleEmail}
                        />
                    */ }
                </Box>
                <Box className="alerts-setup-container">
                    <Typography className="alert-input-name" variant="body1">Discord</Typography>
                    <Button className="discord-setup-button" variant="contained" color="secondary" onClick={() => setIsDiscordSetupModalOpen(true)}>
                        <Typography variant="body1">Setup</Typography>
                    </Button>
                </Box>
                { /*
                    <Box className="alerts-setup-container">
                        <Typography className="alert-input-name" variant="body1">Telegram</Typography>
                        <Button variant="contained" color="secondary" onClick={() => setIsTelegramSetupModalOpen(true)}>
                            <Typography variant="body1">Setup</Typography>
                        </Button>
                    </Box>
                */ }
                { /*
                    <Box className="webhook-container">
                        <Typography className="webhook-title" variant="body1">Webhook URL:</Typography>
                        <Typography variant="body1">api.xyz.com/untitledUIOrg</Typography>
                    </Box>
                */ }
                <Box className="alert-submission">
                    <Button variant="outlined" color="primary" className="cancel-button">
                        <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button className="save-button" variant="contained" color="secondary" onClick={() => setIsAlertModalOpen(true)}>
                        <Typography variant="body1">Save</Typography>
                    </Button>
                </Box>
            </Page>
            <AlertModal
                isOpen={isAlertModalOpen}
                handleClose={() => setIsAlertModalOpen(false)}
                handleSubmit={handleSaveAlerts}
                icon={CheckCircleOutlineIcon}
                header="Alerts Successfully Created!"
                copy="You will begin receiving alerts based on the settings you provided."
                confirmationText="Confirm"
                color="#497B46"
                hoverColor="#2F612C"
            />
            <AlertSetupModal
                isOpen={isDiscordSetupModalOpen}
                handleClose={() => setIsDiscordSetupModalOpen(false)}
                currentId={discordId}
                currentEndpoint={discordEndpoint}
                currentTeam={currentTeam}
            />
            { /* <AlertSetupModal
                isOpen={isTelegramSetupModalOpen}
                handleClose={() => setIsTelegramSetupModalOpen(false)}
                name="Telegram"
                currentWorkspace={currentWorkspace}
            />
            */ }
        </>
    );
}
