import SearchIcon from "@mui/icons-material/Search";
import { Box, Input, InputAdornment } from "@mui/material";

export const SearchBar = () => {
    return (
        <Box>
            <Input
                fullWidth
                disableUnderline
                className="searchbar"
                sx={{ borderColor: "transparent", borderRadius: "6px" }}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                placeholder="Search tests"
            />
        </Box>
    );
}