import "./Sidebar.scss";

import { Button, Divider, Menu, MenuItem, Paper, Select, Skeleton, Typography } from "@mui/material"
import { ExternalSidebarTab, SidebarTab } from "./components/SidebarTab"
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
// import { UsedSpace } from "./components/UsedSpace";
import { CurrentUser } from "./components/CurrentUser";
import { useAuth0 } from "@auth0/auth0-react";
import { Team } from "../../types";
import { useMemo, useState } from "react";
import { CreateWorkspaceModal } from "../CreateWorkspaceModal";
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { useTeams } from "../../hooks/useTeams";

interface SidebarProps {
    currentTeam: Team;
    setCurrentTeam: (team: Team) => void;
}

export const Sidebar = ({ currentTeam, setCurrentTeam }: SidebarProps) => {
    const { data: teamsData, isLoading: isTeamsLoading } = useTeams();
    const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);
    const [createWorkspaceTeamId, setCreateWorkspaceTeamId] = useState("");
    const [createWorkspaceTeamName, setCreateWorkspaceTeamName] = useState("");
    const { user } = useAuth0();
    const navigate = useNavigate();

    const teams = useMemo(() => {
        if (teamsData && teamsData.length > 0) return teamsData;
        return [{
            id: "",
            name: "",
            createdDate: "",
            createdUser: "",
            active: true
        }];
    }, [teamsData, isTeamsLoading])

    const handleCloseModal = () => {
        setIsCreateWorkspaceModalOpen(false);
        setCreateWorkspaceTeamId("");
        setCreateWorkspaceTeamName("");
    }

    const connectedHeader = () => {
        return (
            <Box className="connected-header">
                <Link to={`/${currentTeam.id}`}>
                    <img className="gui-logo" src="/assets/GuardianUI-green-icon.png" alt="" />
                </Link>
                <Divider orientation="vertical" flexItem />
                {
                    isTeamsLoading ?
                        <Skeleton width={"100%"} /> :
                        <Select
                            className="workspace-select"
                            value={
                                teams.findIndex((team: Team) => team.id === currentTeam.id) === -1 ?
                                    0 :
                                    teams.findIndex((team: Team) => team.id === currentTeam.id)
                            }
                            fullWidth
                        >
                            {
                                teams?.map((team: Team, index: number) => {
                                    return (
                                        <MenuItem
                                            key={team.id}
                                            value={index}
                                            onClick={() => navigate(`/${team.id}`)}
                                        >
                                            <Typography className="workspace-name" noWrap variant="body1" color="#497B46">
                                                {`${team.name}`}
                                            </Typography>
                                        </MenuItem>
                                    );
                                })
                            }
                            <Divider />
                            <MenuItem>
                                <Button
                                    className="create-workspace-button"
                                    fullWidth
                                    onClick={() => setIsCreateWorkspaceModalOpen(true)}
                                    sx={{ display: "flex", justifyContent: "flex-start", paddingLeft: "0px", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0)" } }}
                                >
                                    <Typography noWrap variant="body1" color="#497B46">
                                        Create Workspace
                                    </Typography>
                                </Button>
                            </MenuItem>
                        </Select>
                }
            </Box>
        );
    }

    const disconnectedHeader = () => {
        return (
            <Box className="disconnected-header">
                <Link to={`/${currentTeam.id}`}>
                    <img className="gui-logo" src="/assets/GuardianUI-green-logo-2.svg" alt="" />
                </Link>
            </Box>
        );
    }

    return (
        <>
            <Paper className="sidebar">
                <Box className="logo">
                    {user ? connectedHeader() : disconnectedHeader()}
                </Box>
                <Box className="sidebar-links">
                    <Box className="top">
                        <Box className="nav-pages">
                            <SidebarTab icon={HomeOutlinedIcon} name={"Dashboard"} target={`/${currentTeam.id}`} />
                            <SidebarTab icon={MonitorHeartOutlinedIcon} name={"Submit Tests"} target={`/${currentTeam.id}/create-test`} />
                            <SidebarTab icon={WarningAmberOutlinedIcon} name={"Alerts"}  target={`/${currentTeam.id}/alerts`} />
                            { /* <SidebarTab icon={AccountBalanceWalletOutlinedIcon} name={"Billing"} target="/billing" /> */ }
                            <SidebarTab icon={GroupOutlinedIcon} name={"Teams"} target={`/${currentTeam.id}/teams`} />
                        </Box>
                    </Box>
                    <Box className="bottom">
                        <Box className="utility-pages">
                            <ExternalSidebarTab icon={SupportOutlinedIcon} name={"Docs"} target="https://docs.guardianui.com/overview/readme" />
                            <ExternalSidebarTab icon={ModeEditOutlineOutlinedIcon} name={"Request Custom Test"} target="https://airtable.com/shrIzrQxUZqBOKabG" />
                            <ExternalSidebarTab icon={FeedbackOutlinedIcon} name={"Community Feedback"} target="https://discord.gg/2vQ9T4tJBg" />
                            <CurrentUser currentTeam={currentTeam} />
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <CreateWorkspaceModal
                isOpen={isCreateWorkspaceModalOpen}
                handleClose={handleCloseModal}
                currentTeamName={createWorkspaceTeamName}
            />
        </>
    );
}