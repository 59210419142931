import "./AlertModal.scss";

import { Box, Button, Modal, SvgIcon, Typography } from "@mui/material";

interface AlertModalProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    icon: React.ElementType<any>;
    header: string;
    copy: string;
    confirmationText: string;
    color: string;
    hoverColor: string;
}

export const AlertModal = ({ isOpen, handleClose, handleSubmit, icon, header, copy, confirmationText, color, hoverColor }: AlertModalProps) => {
    return (
        <Modal className="gui-modal alert-modal" open={isOpen} onClose={handleClose}>
            <Box className="alert-modal-container">
                <Box className="alert-modal-content">
                    <SvgIcon className="gui-modal-icon" component={icon} sx={{ fill: `${color}` }} />
                    <Typography className="gui-modal-title" variant="h5" color={color}>{header}</Typography>
                    <Typography
                        className="gui-modal-copy"
                        variant="body1"
                        color="text.primary"
                    >
                        {copy}
                    </Typography>
                </Box>
                <Box className="alert-modal-cta">
                    <Button variant="outlined" color="primary" className="cancel" onClick={handleClose}>
                        <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button
                        className="confirm"
                        sx={{ backgroundColor: `${color}`, "&:hover": { backgroundColor: `${hoverColor}` } }}
                        onClick={handleSubmit}
                    >
                        <Typography variant="body1" color="white">{confirmationText}</Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}