import { Box, Button, Typography } from "@mui/material";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Team } from "src/types";

interface CurrentUserProps {
    currentTeam: Team;
}

export const CurrentUser = ({ currentTeam }: CurrentUserProps) => {
    const { user, logout } = useAuth0();

    return (
        <Box className="account-info">
            <Box className="user-values">
                <Link to={`/${currentTeam.id}/edit-user`}>
                    { /* <Typography variant="body1" color="primary">{`${user?.given_name} ${user?.family_name}`}</Typography> */ }
                    <Typography variant="body1" color="text.secondary">{user?.email}</Typography>
                </Link>
            </Box>
            <Button
                className="logout-icon-button"
                onClick={() => logout({ returnTo: window.location.origin })}
            >
                <LogoutOutlinedIcon />
            </Button>
        </Box>
    );
}