import "./TestList.scss";

import { Box, Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useMemo, useState } from "react";
import { AlertModal } from "../AlertModal";
import { useDeleteTest, useTests } from "../../hooks/useTests";
import { Column, Team } from "../../types";
import { useProjects } from "../../hooks/useProjects";
import { useAllExecutions } from "../../hooks/useExecutions";

const columns: Column[] = [
        { id: "statusIcon", label: "" },
        { id: "name", label: "Name" },
        { id: "latestExecution", label: "Last Run" },
        { id: "dailyPassPct", label: "Last Day" },
        { id: "weeklyPassPct", label: "Last Week" },
        { id: "createdDate", label: "Created On" },
        { id: "status", label: "Status" },
        { id: "edit", label: "" },
        { id: "delete", label: "" }
    ]

interface TestListProps {
    currentTeam: Team;
}

export const TestList = ({ currentTeam }: TestListProps) => {
    const [alertModalId, setAlertModalId] = useState("");
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const { data: projectsData } = useProjects(currentTeam.id);
    const { data: testsDisplayData, isLoading: isTestsDataLoading } = useTests(currentTeam.id, projectsData ? projectsData[0].id : "");

    const deleteTestMutation = useDeleteTest();

    const tests = useMemo(() => {
        if (!testsDisplayData) return [];
        return testsDisplayData;
    }, [testsDisplayData]);

    const handleOpenAlertModal = (testId: string) => {
        setAlertModalId(testId);
        setIsAlertModalOpen(true);
    };

    const handleDeleteTest = (testId: string) => {
        deleteTestMutation.mutate({ teamId: currentTeam.id, projectId: projectsData![0].id, testId: testId });
        setIsAlertModalOpen(false);
    };

    const LoadingTableRow = () => {
        return (
            <TableRow sx={{ width: "100%" }}>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
                <TableCell align="center">
                    <Skeleton />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            <TableContainer className="test-list-container">
                <Table className="test-list-table" stickyHeader>
                    <TableHead className="test-list-head-container">
                        <TableRow className="test-list-head">
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isTestsDataLoading || testsDisplayData?.length == 0 ?
                                <LoadingTableRow /> :
                                tests?.map(test => {
                                    return (
                                        <TableRow hover key={test.id}>
                                            {columns.map(column => {
                                                if (column.id === "statusIcon") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/${currentTeam.id}/test/${test.id}`} key={column.id} className="test-link">
                                                                {
                                                                    test.latestExecution && test.latestExecution.status === "Pass"
                                                                        ? <CheckCircleOutlineOutlinedIcon className="passing-icon" />
                                                                        : <WarningAmberOutlinedIcon className="failing-icon" />
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                } else if (column.id === "status") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align} sx={{ minWidth: "70px" }}>
                                                            <Link to={`/${currentTeam.id}/test/${test.id}`} key={column.id} className="test-link">
                                                                {
                                                                    !test.latestExecution ?
                                                                        <Box className="na-status">
                                                                            <Typography color="error">N/A</Typography>
                                                                        </Box> :
                                                                        test.latestExecution.status === "Pass" ?
                                                                            <Box className="passing-status">
                                                                                <Typography color="primary">Passing</Typography>
                                                                            </Box> :
                                                                            test.latestExecution.status === "Running" || test.latestExecution.status === "" ?
                                                                                <Box className="running-status">
                                                                                    <Typography color="primary">Running</Typography>
                                                                                </Box> :
                                                                                    <Box className="failing-status">
                                                                                        <Typography color="error">Failing</Typography>
                                                                                    </Box>
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                
                                                } else if (column.id === "edit") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/${currentTeam.id}/edit-test/${test.id}`}>
                                                                <Button className="edit-button" data-testid="edit-test-button">
                                                                    <EditOutlinedIcon />
                                                                </Button>
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                } else if (column.id === "delete") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Button className="delete-button" data-testid="delete-button" onClick={() => handleOpenAlertModal(test.id)}>
                                                                <DeleteOutlineOutlinedIcon />
                                                            </Button>
                                                        </TableCell>
                                                    );
                                                } else if (column.id === "dailyPassPct" || column.id === "weeklyPassPct") {
                                                    const value: any = test[column.id as keyof typeof test];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/${currentTeam.id}/test/${test.id}`} key={column.id} className="test-link">
                                                                <Typography color="text.primary">
                                                                    {value === "N/A" ? value : `${value}%`}
                                                                </Typography>
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                } else if (column.id === "createdDate") {
                                                    const value: any = test[column.id as keyof typeof test];
                                                    const dateString = new Date(value).toLocaleString(
                                                        "en-US",
                                                        {
                                                            year: "numeric",
                                                            month: "numeric",
                                                            day: "numeric",
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                            timeZone: "UTC",
                                                            timeZoneName: "short"
                                                        }
                                                    ).replace(", ", " - ");
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/${currentTeam.id}/test/${test.id}`} key={column.id} className="test-link">
                                                                <Typography color="text.primary">{dateString}</Typography>
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                } else if (column.id === "latestExecution") {
                                                    const value: any = test[column.id as keyof typeof test];
                                                    const dateString = value ?
                                                        new Date(value.startDate).toLocaleString(
                                                            "en-US",
                                                            {
                                                                year: "numeric",
                                                                month: "numeric",
                                                                day: "numeric",
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                                timeZone: "UTC",
                                                                timeZoneName: "short"
                                                            }
                                                        ).replace(", ", " - ") :
                                                        "N/A";

                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/${currentTeam.id}/test/${test.id}`} key={column.id} className="test-link">
                                                                <Typography color="text.primary">{value ? dateString : "N/A"}</Typography>
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                } else {
                                                    const value: any = test[column.id as keyof typeof test];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/${currentTeam.id}/test/${test.id}`} key={column.id} className="test-link">
                                                                <Typography color="text.primary">{value}</Typography>
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AlertModal
                isOpen={isAlertModalOpen}
                handleClose={() => setIsAlertModalOpen(false)}
                handleSubmit={() => handleDeleteTest(alertModalId)}
                icon={ErrorOutlineIcon}
                header="Delete Test?"
                copy="Are you sure you want to delete this test? This action cannot be undone."
                confirmationText="Delete"
                color="#D92D20"
                hoverColor="#AD1C11"
            />
        </>
    )
}