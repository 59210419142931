export const getCronSchedule = (frequency: string): string => {
    switch (frequency) {
        case "1m":
            return "*/1 * * * *";
        case "5m":
            return "*/5 * * * *";
        case "10m":
            return "*/10 * * * *";
        case "15m":
            return "*/15 * * * *";
        case "30m":
            return "*/30 * * * *";
        case "1h":
            return "0 */1 * * *";
        case "3h":
            return "0 */3 * * *";
        case "6h":
            return "0 */6 * * *";
        case "12h":
            return "0 */12 * * *";
        case "24h":
            return "0 0 */1 * *";
        default:
            throw new Error("Invalid frequency");
    }
};

export const getFrequency = (cronSchedule: string): string => {
    switch (cronSchedule) {
        case "":
            return "Run Frequency";
        case "*/1 * * * *":
            return "1m";
        case "*/5 * * * *":
            return "5m";
        case "*/10 * * * *":
            return "10m";
        case "*/15 * * * *":
            return "15m";
        case "*/30 * * * *":
            return "30m";
        case "0 */1 * * *":
            return "1h";
        case "0 */3 * * *":
            return "3h";
        case "0 */6 * * *":
            return "6h";
        case "0 */12 * * *":
            return "12h";
        case "0 0 */1 * *":
            return "24h";
        default:
            throw new Error("Invalid cron schedule");
    }
};
