import "./CreateWorkspaceModal.scss";

import TabIcon from '@mui/icons-material/Tab';
import { Box, Button, Modal, Typography } from '@mui/material';
import { TitledTextEntry } from '../TitledTextEntry';
import { useMemo, useState } from "react";
import { useCreateWorkspace } from "../../hooks/useWorkspaces";

interface CreateWorkspaceModalProps {
    isOpen: boolean;
    handleClose: () => void;
    currentTeamName: string;
}

export const CreateWorkspaceModal = ({ isOpen, handleClose, currentTeamName }: CreateWorkspaceModalProps) => {
    const [teamName, setTeamName] = useState(currentTeamName);
    // const [projectName, setProjectName] = useState("");
    const [hasSubmissionAttempt, setHasSubmissionAttempt] = useState(false);

    const createWorkspaceMutation = useCreateWorkspace();

    const showTestNameError = useMemo(() => {
        if (teamName == "" && hasSubmissionAttempt) {
            return true;
        }

        return false;
    }, [teamName, hasSubmissionAttempt]);

    // const showProjectNameError = useMemo(() => {
    //     if (projectName == "" && hasSubmissionAttempt) {
    //         return true;
    //     }

    //     return false;
    // }, [projectName, hasSubmissionAttempt]);

    const handleCreateWorkspace = async () => {
        if (teamName == "") {
            setHasSubmissionAttempt(true);
            return;
        } else {
            await createWorkspaceMutation.mutateAsync({
                teamName: teamName,
                projectName: "Untitled Project"
            });
            
            setHasSubmissionAttempt(false);
            handleClose();
        }
    }

    return (
        <Modal className="gui-modal create-workspace-modal" open={isOpen} onClose={handleClose}>
            <Box className="create-workspace-modal-container">
                <Box className="create-workspace-modal-header">
                    <TabIcon className="gui-modal-icon" />
                    <Typography
                        className="gui-modal-title"
                        variant="h5"
                        color="primary"
                    >
                        Create a workspace
                    </Typography>
                </Box>
                <Box className="create-workspace-modal-content">
                    <TitledTextEntry
                        className="workspace-name-entry"
                        title="Workspace Name"
                        placeholder={currentTeamName != "" ? currentTeamName : "Untitled Team"}
                        value={teamName}
                        error={showTestNameError}
                        onChange={(e) => setTeamName(e.target.value)}
                    />
                    { /*
                        <TitledTextEntry
                            title="Project Name"
                            placeholder="Untitled Frontend"
                            value={projectName}
                            error={showProjectNameError}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                    */ }
                </Box>
                <Box className="create-workspace-modal-cta">
                    <Button
                        variant="outlined"
                        color="primary"
                        className="cancel"
                        onClick={() => {
                            setTeamName(currentTeamName);
                            // setProjectName("");
                            handleClose();
                        }}
                    >
                        <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button
                        className="confirm"
                        sx={{ backgroundColor: "primary.main", "&:hover": { backgroundColor: "#2F612C" } }}
                        onClick={handleCreateWorkspace}
                    >
                        <Typography variant="body1" color="white">Create</Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
