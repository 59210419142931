import Editor, { EditorProps, useMonaco } from "@monaco-editor/react";
import { Box } from "@mui/material";
import axios from "axios";
import { useEffect } from "react"
import { codeTemplate } from "../../constants/constants";

// eslint-disable-next-line import/no-webpack-loader-syntax
const playwrightTypings = require("!!raw-loader?esModule=false!../../frameworkTypes/playwrightTest.d.ts");

// eslint-disable-next-line import/no-webpack-loader-syntax
const frameworkTypings = require("!!raw-loader?esModule=false!../../frameworkTypes/framework.d.ts");

export const CodeEditor = ({ ...props }: EditorProps) => {
    const monaco = useMonaco();

    useEffect(() => {
        monaco?.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: false,
            noSyntaxValidation: false,
        });

        monaco?.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco?.languages.typescript.ScriptTarget.ES2016,
            allowNonTsExtensions: true,
            noLib: true
        });

        // Check if models already exist
        var playwrightInstalled: boolean = false;
        var frameworkInstalled: boolean = false;
        const models = monaco?.editor.getModels();
        
        if (models) {
            models.forEach((model: any) => {
                if (model.uri.path === "/src/frameworkTypes/playwrightTest.d.ts") playwrightInstalled = true;
                if (model.uri.path === "/src/frameworkTypes/framework.d.ts") frameworkInstalled = true;
            });
        }

        if (!playwrightInstalled) {
            const libSource = `declare module "@playwright/test" {${playwrightTypings}}`;
            monaco?.languages.typescript.javascriptDefaults.addExtraLib(libSource);
            monaco?.editor.createModel(libSource, "typescript", monaco.Uri.parse(`file:///src/frameworkTypes/playwrightTest.d.ts`));
        }

        if (!frameworkInstalled) {
            const frameworkLibSource = `declare module "@guardianui/test" {${frameworkTypings}}`;
            monaco?.languages.typescript.javascriptDefaults.addExtraLib(frameworkLibSource);
            monaco?.editor.createModel(frameworkLibSource, "typescript", monaco.Uri.parse(`file:///src/frameworkTypes/framework.d.ts`));
        }
    }, [monaco]);
    
    return (
        <Box display="flex">
            <Editor
                height="45vh"
                width="100%"
                language="typescript"
                theme="vs-dark"
                {...props}
            />
        </Box>
    );
}