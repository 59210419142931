import "./LoginButton.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button className="login-button" onClick={() => loginWithRedirect()}>
            <Typography variant="h6" color="white">Login</Typography>
        </Button>
    );
}