import { Box, Typography } from "@mui/material"
import { LoginButton } from "../LoginButton/LoginButton"

export const UnauthorizedLanding = () => {
    return (
        <Box className="unauthorized-landing-page">
            <Box className="unauthorized-landing-page__content">
                <img className="gui-logo" src="/assets/GuardianUI-green-logo-2.svg" alt="" />
                <Typography variant="h4" color="#497B46">Welcome to GuardianUI</Typography>
                <Typography variant="body1" color="#667085">
                    You are moments away from securing your frontend
                </Typography>
                <LoginButton />
            </Box>
        </Box>
    );
}