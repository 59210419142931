import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    direction: "ltr",
    typography: {
        fontSize: 16,
        fontFamily: "Inter",
        h4: {
            fontSize: "30px",
            fontWeight: "500",
            lineHeight: "38px"
        },
        h5: {
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "24px",
        },
        h6: {
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
        },
        body1: {
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
        },
        button: {
            textTransform: "none",
            "&:hover": {
                background: "#F9FAFB",
            },
        },
    },
    palette: {
        primary: {
            main: "#497B46",
        },
        secondary: {
            main: "#717BBC",
        },
        text: {
            primary: "#667085",
            secondary: "#344054",
        },
        error: {
            main: "#D92D20",
        },
        success: {
            main: "#497B46",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {},
                containedSecondary: {
                    height: "40px",
                    background: "#717BBC",
                    color: "#FFFFFF",
                    fontWeight: 600,
                    textTransform: "none",
                    padding: "10px 16px",
                    border: "1px solid #717BBC",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: "8px",
                    "&:hover": {
                        background: "#5C66AC",
                    }
                },
                outlinedPrimary: {
                    height: "40px",
                    background: "#FFFFFF",
                    color: "#344054",
                    fontWeight: 600,
                    textTransform: "none",
                    padding: "10px 16px",
                    border: "1px solid #D0D5DD",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: "8px",
                }
            },
            defaultProps: {
                disableElevation: true,
                disableFocusRipple: true,
                disableRipple: true,
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.gui-page": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "85%",
                        padding: "36px",
                    }
                },
            },
        },
    },
});