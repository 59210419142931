import { Auth0Provider } from '@auth0/auth0-react';

export const Auth0ProviderWithHistory = ({ children }: any) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : "";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : "";
    const audience = process.env.REACT_APP_AUTH0_FRONTEND_API_AUDIENCE ? process.env.REACT_APP_AUTH0_FRONTEND_API_AUDIENCE : "";

    const onRedirectCallback = (appState: any) => {
        // Will set the url to our base domain without any query params
        // This is only used on login so we don't have to worry about any pathnames
        const newUrl = `${window.location.protocol}//${window.location.host}/`;
        window.history.replaceState({}, "", newUrl);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience={audience}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            scope="offline_access"
        >
            {children}
        </Auth0Provider>
  );
};
