import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosResponse } from "axios";

export const getApiEndpoint = (): string => {
    const endpoint: string | undefined = process.env.REACT_APP_FRONTEND_API;

    if (!endpoint) {
        throw new Error("The REACT_APP_FRONTEND_API variable must be set");
    }

    return endpoint;
}

export const getApiAccessTokenParams = (): GetTokenSilentlyOptions => {
    const audience: string | undefined = process.env.REACT_APP_AUTH0_FRONTEND_API_AUDIENCE;

    if (!audience) {
        throw new Error("The REACT_APP_AUTH0_FRONTEND_API_AUDIENCE variable must be set");
    }

    return {
        authorizationParams: {
            audience: audience,
            ignoreCache: true,
            scope: "offline_access"
        }
    };
}

export const getAuthorization = async (getAccessTokenSilently: any) => {
    const apiAccessParams = getApiAccessTokenParams();

    const token = await getAccessTokenSilently(apiAccessParams);
    if (!token) throw new Error("Unauthorized");

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return config;
}

export const authorizedGetRequest = async (endpoint: string, accessToken: any): Promise<AxiosResponse> => {
    const apiUrl = getApiEndpoint();

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }

    const response = await axios.get(`${apiUrl}/${endpoint}`, config);
    if (response.status !== 200 || !response.data.success) throw new Error(`API: Failed to get ${endpoint}`);

    return response;
}

export const authorizedPostRequest = async (endpoint: string, data: any, accessToken: any): Promise<AxiosResponse> => {
    const apiUrl = getApiEndpoint();

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }

    const response = await axios.post(`${apiUrl}/${endpoint}`, data, config);
    if (response.status !== 200 || !response.data.success) throw new Error(`API: Failed to post ${data} to ${endpoint}`);

    return response;
}

export const authorizedPutRequest = async (endpoint: string, data: any, accessToken: any): Promise<AxiosResponse> => {
    const apiUrl = getApiEndpoint();

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }

    const response = await axios.put(`${apiUrl}/${endpoint}`, data, config);
    if (response.status !== 200 || !response.data.success) throw new Error(`API: Failed to put ${data} to ${endpoint}`);

    return response;
}

export const authorizedPatchRequest = async (endpoint: string, data: any, accessToken: any): Promise<AxiosResponse> => {
    const apiUrl = getApiEndpoint();

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const response = await axios.patch(`${apiUrl}/${endpoint}`, data, config);
    if (response.status !== 200 || !response.data.success) throw new Error(`API: Failed to patch ${data} to ${endpoint}`);

    return response;
}

export const authorizedDeleteRequest = async (endpoint: string, accessToken: any): Promise<AxiosResponse> => {
    const apiUrl = getApiEndpoint();
    
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }

    const response = await axios.delete(`${apiUrl}/${endpoint}`, config);
    if (response.status !== 200 || !response.data.success) throw new Error(`API: Failed to delete ${endpoint}`);

    return response;
}
