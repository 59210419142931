import "./AlertSetupModal.scss";

import { Box, Button, Modal, Typography } from "@mui/material";
import { TitledTextEntry } from "../TitledTextEntry";
import { SendWhen } from "./components/SendWhen";
import { useEffect, useState } from "react";
import { Team, Workspace } from "../../types";
import { AlertIntegrationInputCreate } from "../../types/AlertIntegrationInputCreate";
import { AlertIntegrationType } from "../../types/AlertIntegration";
import { useCreateAlertIntegration, useUpdateAlertIntegration } from "../../hooks/useAlertIntegration";
import { AlertIntegrationInputUpdate } from "../../types/AlertIntegrationInputUpdate";
import { useProjects } from "../../hooks/useProjects";

interface AlertSetupModalProps {
    isOpen: boolean;
    handleClose: () => void;
    currentId: string;
    currentEndpoint: string;
    currentTeam: Team;
}

// const valueEntryTitle = {
//     "Discord": "URL",
//     "Telegram": "API KEY"
// }

// const valuePlaceholder = {
//     "Discord": "https://discord.com/api/webhooks/...",
//     "Telegram": "1234567890:ABCDEFGHIJKLMNOPQRSTUVWXYZ"
// }

// const firstLineCopy = {
//     "Discord": "Get detailed failure and recovery notifications in any Discord channel. Take a look in our ",
//     "Telegram": "GuardianUI integrates with and can deliver failure and recovery alerts to any chat (direct or groups). Review our "
// }

// const secondLineCopy = {
//     "Discord": " to see how to get a webhook URL from your Discord server to get started.",
//     "Telegram": " for how to enable the Telegram alert channel."
// }

export const AlertSetupModal = ({ isOpen, handleClose, currentId, currentEndpoint, currentTeam }: AlertSetupModalProps) => {
    const [endpoint, setEndpoint] = useState(currentEndpoint);
    const { data: projectData } = useProjects(currentTeam.id);

    useEffect(() => {
        setEndpoint(currentEndpoint);
    }, [currentEndpoint]);

    const createAlertIntegrationMutation = useCreateAlertIntegration();
    const updateAlertIntegrationMutation = useUpdateAlertIntegration();

    const handleCreateAlert = async () => {
        if (endpoint === currentEndpoint || !projectData) {
            return
        } else if (currentEndpoint === "") {
            const newDiscordAlert: AlertIntegrationInputCreate = {
                teamId: currentTeam.id,
                projectId: projectData[0].id,
                newAlertIntegration: {
                    type: AlertIntegrationType.Discord,
                    endpoint: endpoint,
                    active: true
                },
            };
    
            await createAlertIntegrationMutation.mutateAsync(newDiscordAlert);
        } else {
            const updatedDiscordAlert: AlertIntegrationInputUpdate = {
                teamId: currentTeam.id,
                projectId: projectData[0].id,
                alertIntegrationId: currentId,
                endpoint: endpoint,
                
            }

            await updateAlertIntegrationMutation.mutateAsync(updatedDiscordAlert);
        }

        handleClose();
    };

    return (
        <Modal className="gui-modal alert-setup-modal" open={isOpen} onClose={handleClose}>
            <Box className="alert-setup-modal-container">
                <Typography className="alert-setup-title" data-testid="alert-setup-title" variant="h4" color="text.secondary">
                    Discord
                </Typography>
                <Typography className="alert-setup-instructions" data-testid="alert-setup-instructions" variant="body1" color="text.primary">
                    {`Get detailed failure and recovery notifications in any Discord channel. Take a look in our `}
                    <a href="https://guardianui.gitbook.io/beta-documentation/alerts" target="_blank">docs</a>
                    {` to see how to get a webhook URL from your Discord server to get started.`}
                </Typography>
                <TitledTextEntry
                    className="alert-setup-webhook-url"
                    data-testid="alert-setup-webhook-url"
                    title={`Webhook URL`}
                    placeholder={`https://discord.com/api/webhooks/...`}
                    value={endpoint}
                    onChange={(e) => setEndpoint(e.target.value)}
                />
                { /* <SendWhen /> */ }
                <Box className="alert-setup-modal-cta">
                    <Button
                        className="cancel"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setEndpoint(currentEndpoint);
                            handleClose();
                        }}
                    >
                        <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button
                        className="save"
                        sx={{ backgroundColor: "primary.main", "&:hover": { backgroundColor: "#2F612C" } }}
                        onClick={handleCreateAlert}
                    >
                        <Typography variant="body1" color="white">{`Save Discord webhook`}</Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
