import "./EditTest.scss";

import { Alert, Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Skeleton, Snackbar, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AlertModal } from "../../components/AlertModal";
import { CodeEditor } from "../../components/CodeEditor";
import { Page } from "../../components/Page";
import { TitledTextEntry } from "../../components/TitledTextEntry";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link, useParams } from "react-router-dom";
import { useCreateTest, useTest, useUpdateTest } from "../../hooks/useTests";
import { Team, TestInputCreate, TestInputUpdate, Workspace } from "../../types";
import { getCronSchedule, getFrequency } from "../../utils";
import { codeTemplate } from "../../constants/constants";
import { useRunExistingTest, useRunTest } from "../../hooks/useRunTest";
import Editor from "@monaco-editor/react";
import { useProjects } from "../../hooks/useProjects";
import { getRecommendedSolution } from "src/utils/parseTestOutput";

interface EditTestProps {
    currentTeam: Team;
}

export const EditTest = ({ currentTeam }: EditTestProps) => {
    const params = useParams();
    const { data: projectData } = useProjects(currentTeam.id);
    const { data: testData, isLoading } = useTest(currentTeam.id, projectData ? projectData[0].id : "", params.testId as any);

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [runFrequency, setRunFrequency] = useState("Run Frequency");
    const [isCompiling, setIsCompiling] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [compilationOutput, setCompilationOutput] = useState("");
    const [recommendedSolution, setRecommendedSolution] = useState("");
    const [hasSubmissionAttempt, setHasSubmissionAttempt] = useState(false);
    const [isRunSuccessOpen, setIsRunSuccessOpen] = useState(false);
    const [isRunFailureOpen, setIsRunFailureOpen] = useState(false);

    const runExistingTestMutation = useRunExistingTest();
    const editTestMutation = useUpdateTest();

    const test = useMemo(() => {
        if (
            Object.keys(params).length == 0 ||
            !testData ||
            Object.keys(testData).length == 0 ||
            isLoading
        ) {
            return {
                id: "",
                name: "",
                startBlock: 0,
                chainId: 0,
                schedule: "",
                createdUser: "",
                createdDate: "",
                lastRunDate: "",
                active: false,
                code: "",
                latestRun: {},
                currentStatus: "passing",
                dailyPassPct: 100,
                weeklyPassPct: 100,
                runOutput: ""
            };
        };

        return testData;
    }, [testData]);

    const showNameError = useMemo(() => {
        if (name == "" && hasSubmissionAttempt) {
            return true;
        }

        return false;
    }, [name, hasSubmissionAttempt]);

    const showRunFrequencyError = useMemo(() => {
        if (runFrequency == "Run Frequency" && hasSubmissionAttempt) {
            return true;
        }

        return false;
    }, [runFrequency, hasSubmissionAttempt]);

    useEffect(() => {
        if (Object.keys(params).length == 0) return;
        if (isLoading) return;
        if (!test) return;
        if (Object.keys(test).length == 0) return;

        setName(test.name);
        setCode(test.code);
        setRunFrequency(getFrequency(test.schedule));
    }, [params, test, isLoading, currentTeam]);

    const handleOpenModal = async () => {
        // Forces an error check if the runFrequency has not been changed
        if (runFrequency == "Run Frequency" || name == "") {
            setHasSubmissionAttempt(true);
            return;
        } else {
            setIsAlertModalOpen(true);
            setHasSubmissionAttempt(false);
        }
    }

    const handleRunTest = async () => {
        if (!projectData) return;

        if (name === "") {
            setHasSubmissionAttempt(true);
            return;
        } else {
            setCompilationOutput("");
            setRecommendedSolution("");
            setIsCompiling(true);

            const teamId = currentTeam.id;
            const projectId = projectData[0].id;
            const testId = test.id;
            const newCode = code;

            const testRunResult = await runExistingTestMutation.mutateAsync({
                teamId: teamId,
                projectId: projectId,
                testId: testId,
                newCode: newCode,
            });
            setCompilationOutput(testRunResult.output);
            setRecommendedSolution(getRecommendedSolution(testRunResult.output));
            setIsCompiling(false);

            if (testRunResult.status == "Pass") {
                setIsRunSuccessOpen(true);
            } else {
                setIsRunFailureOpen(true);
            }
        }
    };

    const handleSubmitTest = async () => {
        if (!projectData) return;

        setIsSubmitting(true);

        const teamId = currentTeam.id;
        const projectId = projectData[0].id;
        const schedule = getCronSchedule(runFrequency);

        const updatedTest = {
            name: name != test.name ? name : undefined,
            code: code != test.code ? code : undefined,
            schedule: schedule != test.schedule ? schedule : undefined,
        };

        const testUpdate: TestInputUpdate = {
            teamId: teamId,
            projectId: projectId,
            testId: test.id,
            updatedTest: updatedTest,
        };

        await editTestMutation.mutateAsync(testUpdate);
        setIsAlertModalOpen(false);
        setIsSubmitting(false);
    };

    const CtaButton = () => {
        const handleNewTest = () => {
            const confirmation = window.confirm("Are you sure you want to create a new test? All unsaved changes will be lost.");
            if (confirmation) {
                setName("");
                setCode(codeTemplate);
                setRunFrequency("Run Frequency");
            }
        }

        return (
            <Link to={`/${currentTeam.id}/create-test`}>    
                <Button variant="contained" color="secondary" onClick={handleNewTest}>
                    <Typography variant="body1" color="white">New Test</Typography>
                </Button>
            </Link>
        );
    }

    return (
        <>
            <Page
                className="create-test-page"
                title="Edit Test"
                subheader="Edit an existing test."
                button={<CtaButton />}
            >
                <Box className="form-entry">
                    <FormControl className="test-name-field" required>
                        <TitledTextEntry
                            title="Name"
                            value={name}
                            error={showNameError}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormHelperText className="helper-text" error={showNameError}>
                            {showNameError ? `Please enter a name before submitting your test` : ``}
                        </FormHelperText>
                    </FormControl>
                    <FormControl className="run-frequency-field" required>
                        <Select
                            className="run-frequency-select"
                            fullWidth
                            value={runFrequency}
                            label="frequency"
                            notched={false}
                            error={showRunFrequencyError}
                            onChange={(event) => setRunFrequency(event.target.value)}
                        >
                            <MenuItem value="Run Frequency">Run Frequency</MenuItem>
                            <MenuItem value="1m">1m</MenuItem>
                            <MenuItem value="5m">5m</MenuItem>
                            <MenuItem value="10m">10m</MenuItem>
                            <MenuItem value="15m">15m</MenuItem>
                            <MenuItem value="30m">30m</MenuItem>
                            <MenuItem value="1h">1h</MenuItem>
                            <MenuItem value="3h">3h</MenuItem>
                            <MenuItem value="6h">6h</MenuItem>
                            <MenuItem value="12h">12h</MenuItem>
                            <MenuItem value="24h">24h</MenuItem>
                        </Select>
                        <FormHelperText className="helper-text" error={showRunFrequencyError}>
                            {showRunFrequencyError ? `Please select a run frequency before submitting your test` : ``}
                        </FormHelperText>
                    </FormControl>
                </Box>
                <Box className="instructions" data-testid="instructions">
                    <Typography variant="body1">
                        {`Use the code editor provided below to write, run, and submit your tests to our monitoring system. Please refer to our `}
                        <a href="https://docs.guardianui.com/platform/guardiantest/getting-started/writing-your-first-e2e-test" target="_blank">
                            docs
                        </a>
                        {` for more information on writing tests using our framework. At the moment alerts will be triggered based on the success or failure of any test run, even prior to final submission.`}
                    </Typography>
                </Box>
                <Box className="code-editor" sx={{ marginBottom: `${!compilationOutput && "128px"}` }}>
                    {
                        // Shows a loading component of the same height as the code editor
                        isLoading ? <Skeleton variant="rectangular" height={"45vh"} /> :
                            <CodeEditor data-testid="code-editor" value={code} onChange={value => setCode(value as any)} />
                    }
                    <Box className="submission-buttons">
                        <Button
                            className="run-button"
                            variant="outlined"
                            color="primary"
                            disabled={isCompiling || isSubmitting || isLoading}
                            onClick={handleRunTest}
                        >
                            <Typography variant="body1">{!isCompiling ? `Run` : `Running...`}</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={isCompiling || isSubmitting || isLoading}
                            onClick={handleOpenModal}
                        >
                            <Typography variant="body1" color="white">Submit</Typography>
                        </Button>
                    </Box>
                </Box>
                {compilationOutput && (
                    <Box className="output-section">
                        {recommendedSolution !== "" && (
                            <Box className="recommended-solution">
                                <Typography variant="h5" color="#497B46" sx={{ marginBottom: "8px" }}>
                                    Recommendation:
                                </Typography>
                                <Typography variant="body1">
                                    {recommendedSolution}
                                </Typography>
                            </Box>
                        )}
                        <Box className="compilation-output">
                            <Typography variant="h5" color="#497B46" sx={{ marginBottom: "8px" }}>
                                Test Run Output:
                            </Typography>
                            <Editor
                                className="output-viewer"
                                height="30vh"
                                width="100%"
                                language="typescript"
                                value={compilationOutput}
                                theme="vs-dark"
                                options={{
                                    readOnly: true,
                                    minimap: {
                                        enabled: false
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </Page>
            <AlertModal
                isOpen={isAlertModalOpen}
                handleClose={() => setIsAlertModalOpen(false)}
                handleSubmit={handleSubmitTest}
                icon={CheckCircleOutlineIcon}
                header="Confirm Edit!"
                copy="Click confirm to submit your edits."
                confirmationText="Confirm"
                color="#497B46"
                hoverColor="#2F612C"
            />
            <Snackbar open={isRunSuccessOpen} autoHideDuration={6000} onClose={() => setIsRunSuccessOpen(false)}>
                <Alert
                    onClose={() => setIsRunSuccessOpen(false)}
                    severity="success"
                    sx={{ width: '100%', backgroundColor: "#497B46", color: "white" }}
                >
                    Test ran successfully!
                </Alert>
            </Snackbar>
            <Snackbar open={isRunFailureOpen} autoHideDuration={6000} onClose={() => setIsRunFailureOpen(false)}>
                <Alert
                    onClose={() => setIsRunFailureOpen(false)}
                    severity="error"
                    sx={{ width: '100%', backgroundColor: "#D92D20", color: "white" }}
                >
                    Test run failed!
                </Alert>
            </Snackbar>
        </>
    );
}