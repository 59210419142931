import { useAuth0, User } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ExecutionsData } from "../types/ExecutionsData";
import { allExecutionsQueryKey } from "./useExecutions";
import { testsQueryKey } from "./useTests";

export const failingTestsNumberQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useFailingTestsNumber", user, teamId, projectId];
export const useFailingTestsNumber = (teamId: string, projectId: string) => {
    const client = useQueryClient();
    const { user } = useAuth0();

    return useQuery<number, Error>([failingTestsNumberQueryKey(user, teamId, projectId)], async () => {
        if (teamId === "" || projectId === "") return -1;

        const testsData: any[] | undefined = client.getQueryData([testsQueryKey(user, teamId, projectId)]);
        if (!testsData) {
            await client.refetchQueries([testsQueryKey(user, teamId, projectId)]);
            return -1;
        }

        const activeTests = testsData?.filter((test: any) => test.active);
        if (!activeTests) return -1;

        let failingTests = 0;
        for (const test of activeTests) {
            if (!test.latestExecution) continue;

            if (test.latestExecution.status === "Fail") failingTests++;
        }

        return failingTests;
    });
};
