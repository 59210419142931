import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { TestExecution } from "../types";
import { ExecutionsData } from "../types/ExecutionsData";
import { authorizedGetRequest, authorizedPostRequest, getApiAccessTokenParams } from "./useFrontendApi";
import { testsQueryKey } from "./useTests";

export const allExecutionsQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useAllExecutions", user, teamId, projectId];
export const useAllExecutions = (teamId: string, projectId: string) => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<ExecutionsData[], Error>([allExecutionsQueryKey(user, teamId, projectId)], async () => {
        if (!teamId || !projectId) return [];

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());

        const testsData: any[] | undefined = client.getQueryData([testsQueryKey(user, teamId, projectId)]);
        if (!testsData) {
            return [];
        }

        const activeTests = testsData.filter(test => test.active);
        const testIds = activeTests.map(test => test.id);

        if (testIds.length === 0) return [];

        // Get the latest 7 days of executions (max 10,080 runs ago)
        let perTestExecutionsPromises: Promise<any>[] = [];
        testIds.forEach(testId => perTestExecutionsPromises.push(authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}/executions`, accessToken)));

        let perTestExecutionData = await Promise.all(perTestExecutionsPromises);
        let executionResults: ExecutionsData[] = [];

        for (let i = 0; i < perTestExecutionData.length; i++) {
            const testId = testIds[i];
            const executions: any[] = perTestExecutionData[i].data.records!.slice(0, 10080);

            executions.forEach(execution => {
                executionResults.push({
                    testId: testId,
                    ...execution
                });
            });
        }

        return executionResults;
    });
};

export const EXECUTIONS_SINCE_HOURS: number = 168;

// GET /projects/{projectId}/tests/{testId}/executions
export const executionsQueryKey = (user?: User, teamId?: string, projectId?: string, testId?: string, page?: number, onlyFailures?: boolean) => ["useExecutions", user, teamId, projectId, testId, page, onlyFailures];
export const useExecutions = (teamId: string, projectId: string, testId: string, page: number, onlyFailures: boolean) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<ExecutionsData, Error>([executionsQueryKey(user, teamId, projectId, testId, page, onlyFailures)], async () => {
        if (!teamId || !projectId || !testId) return { recordCount: 0, executions: [] };

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const executionsResponse = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}/executions?pageIndex=${page}&sinceHours=${EXECUTIONS_SINCE_HOURS}&orderDirection=desc${onlyFailures ? '&status=Fail' : ""}`, accessToken);
        const executionsData = executionsResponse.data;

        const executions: ExecutionsData = {
            recordCount: executionsData.pagination.recordCount,
            executions: executionsData.records
        }

        return executions;
    });
};

// GET /projects/{projectId}/tests/{testId}/executions/{executionId}
export const executionQueryKey = (user?: User, teamId?: string, projectId?: string, testId?: string, executionId?: string) => ["useExecution", user, teamId, projectId, testId, executionId];
export const useExecution = (teamId?: string, projectId?: string, testId?: string, executionId?: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<TestExecution, Error>([executionQueryKey(user, teamId, projectId, testId, executionId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const response = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}/executions/${executionId}`, accessToken);
        return response.data.record! as TestExecution;
    });
};

// POST /projects/{projectId}/tests/{testId}/executions
export const useExecuteTest = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation<TestExecution, Error, any>(
        async ({ teamId, projectId, testId }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedPostRequest(`teams/${teamId}/projects/${projectId}/tests/${testId}/executions`, {}, accessToken);
            return response.data.record! as TestExecution;
        },
        {
            onError: error => console.error(error),
            onSuccess: data => console.log(data),
        },
    );
}
