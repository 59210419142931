import "./Page.scss";

import { Box, Grid, Paper, PaperProps, Typography } from "@mui/material";

interface PageProps extends PaperProps {
    titleElement?: JSX.Element;
    subheader?: string;
    subheaderElement?: JSX.Element;
    button?: JSX.Element;
}

export const Page = ({ title, titleElement, subheader, subheaderElement, button, ...props }: PageProps) => {
    return (
        <Paper className={`gui-page ${props.className}`}>
            <Grid container alignContent="flex-start">
                <Grid className="gui-page-header" item xs={12}>
                    {
                        titleElement
                            ? <>{titleElement}</>
                            : <Typography variant="h4" color="#497B46">{title}</Typography>
                    }
                    {
                        button
                            ? <>{button}</>
                            : <></>
                    }
                </Grid>
                <Grid className="gui-page-subheader" item xs={12}>
                    {
                        subheaderElement
                            ? <>{subheaderElement}</>
                            : <Typography variant="body1" color="#667085">{subheader}</Typography>
                    }
                </Grid>
                <Box className="gui-page-content-box">{props.children}</Box>
            </Grid>
        </Paper>
    );
}