import { useEffect, useMemo, useState } from 'react';
import './App.scss';
import { Sidebar } from './components/Sidebar';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TestDashboard } from './pages/TestDashboard/TestDashboard';
import { CreateTest } from './pages/CreateTest/CreateTest';
import { TestPage } from './pages/TestPage';
import { CreateAlerts } from './pages/CreateAlerts';
import { Users } from './pages/Users';
import { EditUser } from './pages/EditUser';
import { useAuth0 } from '@auth0/auth0-react';
import { UnauthorizedLanding } from './components/Auth/UnauthorizedLanding';
import { Team } from './types';
import { Box, Typography } from '@mui/material';
import { useTeams } from './hooks/useTeams';
import { useCreateWorkspace } from './hooks/useWorkspaces';
import { EditTest } from './pages/EditTest';

const audience = process.env.REACT_APP_AUTH0_FRONTEND_API_AUDIENCE ? process.env.REACT_APP_AUTH0_FRONTEND_API_AUDIENCE : "";

function App() {
    const [isCreatingTeam, setIsCreatingTeam] = useState(false);
    const [currentTeam, setCurrentTeam] = useState<Team>({
        id: "",
        name: "",
        createdDate: "",
        createdUser: "",
        active: true
    });
    const { isAuthenticated, isLoading, user,  getAccessTokenSilently } = useAuth0();
    const { data: teamData, isLoading: isTeamsLoading } = useTeams();
    const location = useLocation();
    const navigate = useNavigate();

    const createWorkspaceMutation = useCreateWorkspace();

    const getCurrentTeamFromUrl = () => {
        const url = location.pathname;

        const startIndex = url.indexOf("#/") + 2;
        const endIndex = url.indexOf("/", url.indexOf("#/") + 2);

        let teamId: string;

        if (endIndex === -1) {
            teamId = url.slice(startIndex);
        } else {
            teamId = url.slice(startIndex, endIndex);
        }
        
        const team = teamData?.find(team => team.id === teamId);
        return team;
    }

    const setCurrentTeamAndUrl = (team: Team) => {
        navigate(`/${team.id}`);
        setCurrentTeam(team);
    }

    useEffect(() => {
        if (!isAuthenticated && !isLoading && !user) {
            getAccessTokenSilently({
                authorizationParams: {
                  audience: audience,
                  ignoreCache: true,
                  scope: "offline_access"
                },
              })
                .then(token => console.log("User authenticated."))
                .catch(error => console.log("No token stored."));
        }
    }, [isAuthenticated, isLoading, user]);

    useEffect(() => {
        if (
            user &&
            !isTeamsLoading &&
            (!teamData || teamData.length == 0)
        ) {
            console.log("No workspaces found. Creating new one.");
            setIsCreatingTeam(true);

            createWorkspaceMutation.mutateAsync({
                teamName: "My Team",
                projectName: "My Project"
            })
                .then(result => {
                    console.log("Default workspace initialized.")
                    setIsCreatingTeam(false);
                })
                .catch(error => console.error(error));
        }
    }, [user, teamData, isTeamsLoading]);

    useEffect(() => {
        const currentTeamFromUrl = getCurrentTeamFromUrl();

        if (currentTeamFromUrl) {
            setCurrentTeam(currentTeamFromUrl);
            return;
        }

        if (teamData && teamData.length > 0 && !currentTeamFromUrl) {
            const team = teamData[0];
            setCurrentTeamAndUrl(team);
            return;
        }
    }, [teamData, location]);

    return (
        <div className="app">
            {isAuthenticated && !isCreatingTeam ?
                <>
                    <Sidebar
                        currentTeam={currentTeam}
                        setCurrentTeam={setCurrentTeam}
                    />
                    <div className="pages">
                        <Routes>
                            <Route
                                path="/:teamId?"
                                element={<TestDashboard currentTeam={currentTeam} />}
                            />
                            <Route path="/:teamId/create-test" element={<CreateTest currentTeam={currentTeam} />} />
                            <Route path="/:teamId/edit-test/:testId" element={<EditTest currentTeam={currentTeam} />} />
                            <Route
                                path="/:teamId/teams"
                                element={<Users currentTeam={currentTeam} />}
                            />
                            {/* <Route path="billing" element={<Billing />} /> */}
                            <Route path="/:teamId/test/:testId" element={<TestPage currentTeam={currentTeam} />} />
                            <Route path="/:teamId/alerts" element={<CreateAlerts currentTeam={currentTeam} />} />
                            <Route path="/:teamId/edit-user" element={<EditUser />} />
                        </Routes>
                    </div>
                </>
                :
                !isAuthenticated ?
                <>
                    <UnauthorizedLanding />
                </>
                :
                <Box className="creating-team">
                    <Typography variant="h4" color="primary">Setting up workspace...</Typography>
                </Box>
            }
            
        </div>
    );
}

export default App;
