import "./DataCard.scss";

import { Box, Skeleton, Typography } from "@mui/material";

interface DataCardProps {
    title: string;
    value: string;
    isLoading: boolean;
    color: string;
}

export const DataCard = ({ title, value, isLoading, color }: DataCardProps) => {
    return (
        <Box className="data-card">
            <Typography variant="h6" color={color} align="left" sx={{ width: "100%" }}>
                {title}
            </Typography>
            {
                isLoading ?
                    <Skeleton sx={{ minWidth: "75px" }} /> :
                    <Typography data-testid={title} variant="h3" color={color}>{value}</Typography>
            }
        </Box>
    );
}