import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TeamMember } from "../types";
import { authorizedDeleteRequest, authorizedGetRequest, authorizedPostRequest, authorizedPutRequest, getApiAccessTokenParams, getApiEndpoint } from "./useFrontendApi";

// const organizationUsers: User[] = [
//     { id: 0, name: "Emily Wagner", email: "olivia@untitledui.com", role: "Owner" },
//     { id: 1, name: "Phoenix Baker", email: "phoenix@untitledui.com", role: "Read" },
//     { id: 2, name: "Lana Steiner", email: "lana@untitledui.com", role: "Read and Write" },
//     { id: 3, name: "Pending Invite", email: "demi@untitledui.com", role: "Admin" }
// ]; // mock data. this will be pulled from the api in prod

// GET /teams/{teamId}/members
export const membersQueryKey = (user?: User, teamId?: string) => ["useMembers", user, teamId];
export const useMembers = (teamId: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<TeamMember[], Error>([membersQueryKey(user, teamId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        if (teamId === "") return [] as TeamMember[];

        const response = await authorizedGetRequest(`teams/${teamId}/members`, accessToken);

        const activeUsers: TeamMember[] = response.data!.records.filter((member: TeamMember) => member.active || member.active == undefined);
        return activeUsers;
    });
};

// GET /teams/{teamId}/members/{memberId}
export const memberQueryKey = (user?: User, teamId?: string, memberId?: string) => ["useMember", user, teamId, memberId];
export const useMember = (teamId: string, memberId: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<TeamMember, Error>([memberQueryKey(user, teamId, memberId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const response = await authorizedGetRequest(`teams/${teamId}/members/${memberId}`, accessToken);
        return response.data!.record as TeamMember;
    });
};

// POST /teams/{teamId}/members
export const useAddTeamMember = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<any, Error, any>(
        async ({ teamId, email }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const addUserRequestBody = {
                email: email,
            };

            const response = await authorizedPostRequest(`teams/${teamId}/members`, addUserRequestBody, accessToken);
            return response.data!.record as TeamMember;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = membersQueryKey(user, inputs.teamId);
                await client.refetchQueries([keyToRefetch]);
            }
        }
    );
}

// PUT /teams/{teamId}/members/{memberId}
export const useUpdateTeamMember = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<TeamMember, Error, any>(
        async ({ teamId, memberId, userId, active }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedPutRequest(`teams/${teamId}/members/${memberId}`, { userId, active }, accessToken);
            return response.data!.record as TeamMember;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    membersQueryKey(user, inputs.teamId),
                    memberQueryKey(user, inputs.teamId, inputs.memberId),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        }
    );
}

// DELETE /teams/{teamId}/members/{memberId}
export const useDeleteTeamMember = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<any, Error, any>(
        async ({ teamId, memberId }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedDeleteRequest(`teams/${teamId}/members/${memberId}`, accessToken);
            return response.data!.success as boolean;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    membersQueryKey(user, inputs.teamId),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            }
        }
    );
}
