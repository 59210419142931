import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Project, ProjectInputCreate, ProjectInputUpdate } from "../types";
import { authorizedDeleteRequest, authorizedGetRequest, authorizedPostRequest, authorizedPutRequest, getApiAccessTokenParams, getApiEndpoint } from "./useFrontendApi";
import { workspacesQueryKey } from "./useWorkspaces";

// POST /teams/{teamId}/projects
export const useCreateProject = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<Project, Error, ProjectInputCreate>(
        async (newProject: ProjectInputCreate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedPostRequest(`teams/${newProject.teamId}/projects`, newProject, accessToken);
            return response.data.record as Project;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    projectsQueryKey(user, inputs.teamId),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        },
    );
};

// GET /teams/{teamId}/projects
export const projectsQueryKey = (user?: User, teamId?: string) => ["useProjects", user, teamId];
export const useProjects = (teamId?: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<Project[], Error>([projectsQueryKey(user, teamId)], async () => {
        if (!teamId || teamId === "") return [];

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const response = await authorizedGetRequest(`teams/${teamId}/projects`, accessToken);
        const projects: Project[] = response.data.records.filter((project: Project) => (project.active === true || project.active === undefined));
        return projects;
    },
    {
        enabled: teamId !== undefined && teamId !== "",
    }
    );
};

// GET /teams/{teamId}/projects/{projectId}
export const projectQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useProject", user, teamId, projectId];
export const useProject = (teamId: string, projectId: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<Project, Error>([projectQueryKey(user, teamId, projectId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        if (!teamId || teamId === "" || !projectId || projectId === "") return {} as Project;

        const response = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}`, accessToken);
        return response.data.record as Project;
    });
};

// PUT /teams/{teamId}/projects/{projectId}
export const useUpdateProject = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<Project, Error, ProjectInputUpdate>(
        async (project: ProjectInputUpdate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const currentProjectResponse = await authorizedGetRequest(`teams/${project.teamId}/projects/${project.projectId}`, accessToken);
            const currentProject = currentProjectResponse.data.record as Project;

            const newProjectData = {
                name: project.name,
                createdDate: currentProject.createdDate,
                createdUser: currentProject.createdUser,
                active: currentProject.active,
            };

            const response = await authorizedPutRequest(`teams/${project.teamId}/projects/${project.projectId}`, newProjectData, accessToken);
            return response.data.record as Project;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    projectsQueryKey(user, inputs.teamId),
                    projectQueryKey(user, inputs.teamId, inputs.projectId),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        },
    );
};

// DELETE /teams/{teamId}/projects/{projectId}
export const useDeleteProject = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<boolean, Error, any>(
        async ({ teamId, projectId }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedDeleteRequest(`teams/${teamId}/projects/${projectId}`, accessToken);
            return response.data.success;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    projectsQueryKey(user, inputs.teamId),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        },
    );
};
