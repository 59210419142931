import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, OutlinedInput, Typography } from "@mui/material";
import { Page } from "../../components/Page";
import "./EditUser.scss";

export const EditUser = () => {
    const { user } = useAuth0();

    return (
        <Page
            className="edit-user-page"
            title={`${user?.email}`}
        >
            <Box className="personal-info-box">
                <Typography className="personal-info-title" variant="h6">
                    Account Info
                </Typography>
                <Typography className="personal-info-subheader" variant="body1">
                    View the personal details linked to your GuardianUI account.
                </Typography>
            </Box>
            { /* 
                <Box className="name-inputs-container">
                    <Typography className="input-name" variant="body1">Name</Typography>
                    <Box className="inputs-box">
                        <OutlinedInput className="input-box first" value={`${user?.given_name}`} notched={false} disabled />
                        <OutlinedInput className="input-box" value={`${user?.family_name}`} notched={false} disabled />
                    </Box>
                </Box>
            */ }
            <Box className="email-input-container">
                <Typography className="input-name" variant="body1">Email</Typography>
                <Box className="inputs-box">
                    <OutlinedInput className="input-box" fullWidth placeholder={`${user?.email}`} notched={false} disabled />
                </Box>
            </Box>
        </Page>
    );
}
