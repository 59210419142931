import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Test, TestExecution, TestInputCreate } from "../types";
import { delay } from "../utils/delay";
import { allExecutionsQueryKey } from "./useExecutions";
import { authorizedDeleteRequest, authorizedGetRequest, authorizedPatchRequest, authorizedPostRequest, authorizedPutRequest, getApiAccessTokenParams } from "./useFrontendApi";

export const useRunTest = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<any, Error, any>(
        async(newTest: TestInputCreate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            // Temporarily create a test entry in the DB
            const tempTestResponse = await authorizedPostRequest(
                `teams/${newTest.teamId}/projects/${newTest.projectId}/tests`,
                newTest.newTest,
                accessToken
            );
            const tempTest: Test = tempTestResponse.data.record;
            if (!tempTest) throw new Error("Failed to create temporary test entry");

            // Trigger execution for the newly created test
            const executionCreationResponse = await authorizedPostRequest(
                `teams/${newTest.teamId}/projects/${newTest.projectId}/tests/${tempTest.id}/executions`,
                {
                    source: "WebApp"
                },
                accessToken
            );
            const executionCreation: TestExecution = executionCreationResponse.data.record;
            if (!executionCreation) throw new Error("Failed to create execution");

            // Get execution result
            var finishedRunning: boolean = false;
            var executionResult: TestExecution;
            do {
                const executionResultResponse = await authorizedGetRequest(
                    `teams/${newTest.teamId}/projects/${newTest.projectId}/tests/${tempTest.id}/executions/${executionCreation.id}`,
                    accessToken
                );
                executionResult = executionResultResponse.data.record!;
                
                if (executionResult.status != "Pending" && executionResult.status != "Running") {
                    finishedRunning = true;
                } else {
                    await delay(2500);
                }
            } while (!finishedRunning);

            // Delete temporary test entry
            await authorizedDeleteRequest(`teams/${newTest.teamId}/projects/${newTest.projectId}/tests/${tempTest.id}`, accessToken);

            // Return execution result
            return {
                output: executionResult.commandOutput,
                status: executionResult.status
            }
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = allExecutionsQueryKey(user, inputs.teamId, inputs.projectId);
                await client.refetchQueries([keyToRefetch]);
            },
        }
    );
};

export const useRunExistingTest = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<any, Error, any>(
        async({ teamId, projectId, testId, newCode, chain, blockNumber }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            // Cache current test code
            const currentTestResponse = await authorizedGetRequest(
                `teams/${teamId}/projects/${projectId}/tests/${testId}`,
                accessToken
            );
            if (currentTestResponse.status != 200) throw new Error("Failed to get test");
            const currentTest: Test = currentTestResponse.data.record!;

            // Get updated test object
            const codeChanged = currentTest.code != newCode;
            const updatedTestObject = {
                code: codeChanged ? newCode : undefined,
            }

            // Update test code
            if (codeChanged) {
                await authorizedPatchRequest(
                    `teams/${teamId}/projects/${projectId}/tests/${testId}`,
                    updatedTestObject,
                    accessToken
                );
            }

            // Trigger execution for the test
            const executionCreationResponse = await authorizedPostRequest(
                `teams/${teamId}/projects/${projectId}/tests/${testId}/executions`,
                {
                    source: "WebApp"
                },
                accessToken
            );
            const executionCreation: TestExecution = executionCreationResponse.data.record;
            if (!executionCreation) throw new Error("Failed to create execution");

            // Get execution result
            var finishedRunning: boolean = false;
            var executionResult: TestExecution;
            do {
                const executionResultResponse = await authorizedGetRequest(
                    `teams/${teamId}/projects/${projectId}/tests/${testId}/executions/${executionCreation.id}`,
                    accessToken
                );
                executionResult = executionResultResponse.data.record!;

                if (executionResult.status != "Pending" && executionResult.status != "Running") {
                    finishedRunning = true;
                } else {
                    await delay(2500);
                }
            } while (!finishedRunning);

            // Build test object to restore
            const testObjectToRestore = {
                code: codeChanged ? currentTest.code : undefined,
            }

            if (codeChanged) {
                // Restore test code
                await authorizedPatchRequest(
                    `teams/${teamId}/projects/${projectId}/tests/${testId}`,
                    testObjectToRestore,
                    accessToken
                );
            }

            // Return execution result
            return {
                output: executionResult.commandOutput,
                status: executionResult.status
            }
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = allExecutionsQueryKey(user, inputs.teamId, inputs.projectId);
                await client.refetchQueries([keyToRefetch]);
            },
        }
    );
};
