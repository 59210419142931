import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Project, Team, Workspace } from "../types";
import { authorizedGetRequest, authorizedPostRequest, getApiAccessTokenParams } from "./useFrontendApi";
import { teamQueryKey, teamsQueryKey } from "./useTeams";
import { projectQueryKey, projectsQueryKey } from "./useProjects";

export const useCreateWorkspace = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<any, Error, any>(
        async ({ teamName, projectName }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const teamCreationRequestBody = {
                name: teamName,
                active: true,
            };

            const teamCreationResponse = await authorizedPostRequest("teams", teamCreationRequestBody, accessToken);
            const teamId = teamCreationResponse.data.record.id;

            const projectCreationRequestBody = {
                name: projectName,
            };

            const projectCreationResponse = await authorizedPostRequest(`teams/${teamId}/projects`, projectCreationRequestBody, accessToken);
            const projectId = projectCreationResponse.data.record.id;

            return [teamId, projectId];
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data) => {
                console.log(data);

                const keysToRefetch = [
                    workspacesQueryKey(user),
                    teamsQueryKey(user),
                    teamQueryKey(user, data[0]),
                    projectsQueryKey(user, data[0]),
                    projectQueryKey(user, data[0], data[1])
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            }
        }
    )
}

export const workspacesQueryKey = (user?: User) => ["useWorkspaces", user];
export const useWorkspaces = () => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<Workspace[], Error>([workspacesQueryKey(user)], async () => {
        if (!user) return [];

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const teamsResponse = await authorizedGetRequest(`teams`, accessToken);
        const teams = teamsResponse.data.records as Team[];

        const workspaces: Workspace[] = [];


        if (teams.length > 0) {
            for (let i = 0; i < teams.length; i++) {
                const team = teams[i];

                const projectsResponse = await authorizedGetRequest(`teams/${team.id}/projects`, accessToken);
                const projects = projectsResponse.data.records as Project[];
                projects.forEach(project => {
                    workspaces.push({
                        index: i,
                        teamId: team.id,
                        teamName: team.name,
                        projectId: project.id,
                        projectName: project.name,
                    });

                })
            };
        };

        return workspaces;
    });
};
