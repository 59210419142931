import "./TestLogsTable.scss";

import { Box, Button, Collapse, Skeleton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { Column, Team } from "../../types";
import { useMemo, useState } from "react";
import Editor from "@monaco-editor/react";
import { EXECUTIONS_SINCE_HOURS, useExecutions } from "../../hooks/useExecutions";
import { useProjects } from "../../hooks/useProjects";

interface TestLogsTableProps {
    currentTeam: Team;
    testId: string;
}

interface RowInterface {
    date: string;
    runDuration: string;
    status: string;
    source: string;
    output: string
}

const columns: Column[] = [
    { id: "date", label: "Date" },
    { id: "runDuration", label: "Run Duration" },
    { id: "status", label: "Status" },
    { id: "source", label: "Run Source" },
    { id: "output", label: "Output" }
];

const Row = ({ row }: any) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.runDuration}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.source}</TableCell>
                <TableCell>
                    <Button onClick={() => setExpanded(!expanded)}>
                        {`${row.output ? row.output.slice(0, 12) : ""}...`}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingBottom: "0px", paddingTop: "0px" }} colSpan={12}>
                    <Collapse in={expanded}>
                        <Box sx={{ margin: "2px" }}>
                            <Typography variant="h5" color="#497B46" sx={{ marginBottom: "8px" }}>
                                Test Run Output:
                            </Typography>
                            <Editor
                                className="output-viewer"
                                height="30vh"
                                width="100%"
                                language="typescript"
                                value={row.output}
                                theme="vs-dark"
                                options={{
                                    readOnly: true,
                                    minimap: {
                                        enabled: false
                                    }
                                }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const LoadingRow = () => {
    return (
        <TableRow key="loading-row" hover sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
        </TableRow>
    );
}

export const TestLogsTable = ({ currentTeam, testId }: TestLogsTableProps) => {
    const [page, setPage] = useState(0);
    const [filterEnabled, setFilterEnabled] = useState(false);
    const { data: projectData } = useProjects(currentTeam.id);
    const { data: executionsData, isLoading: isExecutionsLoading } = useExecutions(currentTeam.id, projectData ? projectData[0].id : "", testId, page, filterEnabled);

    const isLoading = useMemo(() => {
        return !executionsData || isExecutionsLoading;
    }, [executionsData, isExecutionsLoading]);

    const executions = useMemo(() => {
        if (executionsData) return executionsData.executions
        return [];
    }, [executionsData]);

    const recordCount = useMemo(() => {
        if (executionsData) return executionsData.recordCount
        return 0;
    }, [executionsData]);

    return (
        <Box className="test-logs-table-box">
            <Typography variant="h4">Test Runs</Typography>
            <Box className="test-logs-filter">
                <Typography variant="h6">Filter by failures only</Typography>
                <Switch checked={filterEnabled} onClick={() => setFilterEnabled(!filterEnabled)} />
            </Box>
            <TableContainer className="test-logs-container">
                <Table className="test-logs-table">
                    <TableHead className="test-logs-head-container">
                        <TableRow className="test-logs-head">
                            {columns.map(column => (
                                <TableCell key={column.id} align={column.align}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isLoading || executions.length == 0 ?
                                <LoadingRow /> :
                                executions.map(testRun => {
                                    const runSeconds = Math.floor(testRun.elapsedSeconds);
                                    const dateString = new Date(testRun.startDate).toLocaleString(
                                        "en-US",
                                        {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            timeZone: "UTC",
                                            timeZoneName: "short"
                                        }
                                    ).replace(", ", " - ");
        
                                    const runDisplay: RowInterface = {
                                        date: dateString,
                                        runDuration: `${runSeconds}s`,
                                        status: testRun.status,
                                        source: testRun.source,
                                        output: testRun.commandOutput
                                    };
        
                                    return (
                                        <Row key={testRun.id} row={runDisplay as RowInterface} />
                                    )})
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={recordCount}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                />
            </TableContainer>
        </Box>
    );
};
