// TODO: This should probably be it's own hook and shouldn't rely on an access token being passed in

import { useAuth0, User } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { testQueryKey, testsQueryKey } from "./useTests";

// Time period is in number of minutes (1440 = 1 day, 10080 = 1 week)
export const testRunCountQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useTestRunCount", user, teamId, projectId];
export const useTestRunCount = (teamId: string, projectId: string) => {
    const client = useQueryClient();
    const { user } = useAuth0();

    return useQuery<number, Error>([testRunCountQueryKey(user, teamId, projectId)], async () => {
        if (teamId === "" || projectId === "") return -1;

        const testsData: any[] | undefined = client.getQueryData([testsQueryKey(user, teamId, projectId)]);
        if (!testsData) {
            await client.refetchQueries([testsQueryKey(user, teamId, projectId)]);
            return -1;
        }

        const activeTests = testsData.filter((test: any) => test.active);
        if (!activeTests) return -1;

        let testRuns = 0;
        for (const test of activeTests) {
            const totalTestRuns = 
                test.metrics.statusSummary.Pass +
                test.metrics.statusSummary.Fail +
                test.metrics.statusSummary.Error +
                test.metrics.statusSummary.Pending +
                test.metrics.statusSummary.Running;

            testRuns += totalTestRuns;
        }

        return testRuns;
    });
};

export const singleTestRunCountQueryKey = (user?: User, teamId?: string, projectId?: string, testId?: string) => ["useSingleTestRunCount", user, teamId, projectId, testId];
export const useSingleTestRunCount = (teamId: string, projectId: string, testId: string) => {
    const client = useQueryClient();
    const { user } = useAuth0();

    return useQuery<number, Error>([singleTestRunCountQueryKey(user, teamId, projectId, testId)], async () => {
        if (teamId === "" || projectId === "") return -1;

        const testData: any | undefined = client.getQueryData([testQueryKey(user, teamId, projectId, testId)]);
        if (!testData) {
            await client.refetchQueries([testQueryKey(user, teamId, projectId, testId)]);
            return -1;
        }

        const totalTestRuns =
            testData.metrics.statusSummary.Pass +
            testData.metrics.statusSummary.Fail +
            testData.metrics.statusSummary.Error +
            testData.metrics.statusSummary.Pending +
            testData.metrics.statusSummary.Running;

        return totalTestRuns;
    });
}
