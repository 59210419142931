import "./AddUserModal.scss";

import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { TitledTextEntry } from "../TitledTextEntry";
import { useState } from "react";
import { Team, Workspace } from "../../types";
import { useAddTeamMember } from "../../hooks/useMembers";

interface AddUserModalProps {
    isOpen: boolean;
    handleClose: () => void;
    currentTeam: Team;
}

export const AddUserModal = ({ isOpen, handleClose, currentTeam }: AddUserModalProps) => {
    const theme = useTheme();

    const [userEmail, setUserEmail] = useState("");
    const addTeamMemberMutation = useAddTeamMember();

    const handleInviteUser = async () => {
        const teamId = currentTeam.id;
        await addTeamMemberMutation.mutate({ teamId: teamId, email: userEmail });
        setUserEmail("");
        handleClose();
    };

    return (
        <Modal className="gui-modal add-user-modal" open={isOpen} onClose={handleClose}>
            <Box className="add-user-modal-container">
                <Box className="add-user-modal-header">
                    <PersonAddAltIcon className="gui-modal-icon" />
                    <Typography
                        className="gui-modal-title"
                        variant="h5"
                        color={theme.palette.primary.main}
                    >
                        Invite team member
                    </Typography>
                    <Typography
                        className="gui-modal-copy"
                        variant="body1"
                        color="primary"
                    >
                        Invite colleagues to collaborate.
                    </Typography>
                </Box>
                <Box className="add-user-modal-content">
                    <TitledTextEntry
                        title="Email address"
                        placeholder="you@untitledui.com"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                </Box>
                <Box className="add-user-modal-cta">
                    <Button
                        variant="outlined"
                        color="primary"
                        className="cancel"
                        onClick={() => {
                            setUserEmail("");
                            handleClose();
                        }}
                    >
                        <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button
                        className="confirm"
                        sx={{ backgroundColor: "primary.main", "&:hover": { backgroundColor: "#2F612C" } }}
                        onClick={handleInviteUser}
                    >
                        <Typography variant="body1" color="white">Send Invite</Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
