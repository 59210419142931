import "./Users.scss";

import { Page } from "../../components/Page";
import { UserTable } from "../../components/UserTable";
import { useState } from "react";
import { Box, Button, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { CreateTeamModal } from "../../components/CreateTeamModal";
import { InvitesTable } from "../../components/InvitesTable";
import { Team, Workspace } from "../../types";
import { CreateWorkspaceModal } from "../../components/CreateWorkspaceModal";
import { useTeams } from "../../hooks/useTeams";

interface UsersProps {
    currentTeam: Team;
}

export const Users = ({ currentTeam }: UsersProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isLoading: isTeamsLoading } = useTeams();

    const TitleElement = () => {
        return (
            <Box className="title-element">
                {
                    isTeamsLoading ?
                        <Skeleton sx={{ minWidth: "75px" }} /> :
                        <Typography variant="h4" color="#497B46">{`${currentTeam.name}`}</Typography>
                }
            </Box>
        );
    }

    const CtaButton = () => {
        return (
            <Button variant="contained" color="secondary" onClick={() => setIsModalOpen(true)}>
                <Typography variant="body1" color="white">Create Workspace</Typography>
            </Button>
        )
    }

    return (
        <>
            <Page
                className="users-page"
                titleElement={<TitleElement />}
                subheader="Invite team members and manage existing ones."
                button={<CtaButton />}
            >
                { /* <InvitesTable /> */ }
                <UserTable currentTeam={currentTeam} />
            </Page>
            <CreateWorkspaceModal
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                currentTeamName={""}
            />
        </>
    );
};
