import "./TestDashboard.scss";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import { SearchBar } from "../../components/SearchBar";
import { TestList } from "../../components/TestList/TestList";
import { Link } from "react-router-dom";
import { DataCard } from "../../components/DataCard";
import { Page } from "../../components/Page";
import { useTests } from "../../hooks/useTests";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { useMemo, useState } from "react";
import { Team } from "../../types";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { EditProjectNameModal } from "../../components/EditProjectNameModal";
import { usePassingTestsNumber } from "../../hooks/usePassingTestsNumber";
import { useFailingTestsNumber } from "../../hooks/useFailingTestsNumber";
import { useTestRunCount } from "../../hooks/useTestRunCount";
import { useProjects } from "../../hooks/useProjects";
import { useTeams } from "../../hooks/useTeams";

interface TestDashboardProps {
    currentTeam: Team;
}

export const TestDashboard = ({ currentTeam }: TestDashboardProps) => {
    const [isEditingName, setIsEditingName] = useState(false);
    const { isLoading: isTeamsLoading } = useTeams();
    const { data: projectsData } = useProjects(currentTeam.id);
    const { data: testsData, isLoading: isTestsDataLoading } = useTests(currentTeam.id, projectsData ? projectsData[0].id : "");
    const { data: passingTestData, isLoading: isPassingTestDataLoading } = usePassingTestsNumber(currentTeam.id, projectsData ? projectsData[0].id : "");
    const { data: failingTestData, isLoading: isFailingTestDataLoading } = useFailingTestsNumber(currentTeam.id, projectsData ? projectsData[0].id : "");
    const { data: weeklyTestRunsData, isLoading: isWeeklyTestRunsDataLoading } = useTestRunCount(currentTeam.id, projectsData ? projectsData[0].id : "");

    const areCardsLoading = useMemo(() => {
        return isPassingTestDataLoading || passingTestData == -1 || isFailingTestDataLoading || failingTestData == -1 || isWeeklyTestRunsDataLoading || weeklyTestRunsData == -1;
    }, [isPassingTestDataLoading, passingTestData, isFailingTestDataLoading, failingTestData, isWeeklyTestRunsDataLoading, weeklyTestRunsData]);

    const tests = useMemo(() => {
        if (testsData) return testsData;
        return [];
    }, [testsData]);

    const passingTestCount = useMemo(() => {
        if (passingTestData) return passingTestData;
        return 0;
    }, [passingTestData]);

    const failingTestCount = useMemo(() => {
        if (failingTestData) return failingTestData;
        return 0;
    }, [failingTestData]);

    const weeklyTestRuns = useMemo(() => {
        if (weeklyTestRunsData) return weeklyTestRunsData;
        return 0;
    }, [weeklyTestRunsData]);

    const CtaButton = () => {
        return (
            <Link to={`/${currentTeam.id}/create-test`}>
                <Button variant="contained" color="secondary">
                    <Typography variant="body1" color="white">New Test</Typography>
                </Button>
            </Link>
        );
    }

    const TitleElement = () => {
        return (
            <Box className="title-element">
                {
                    isTeamsLoading ?
                        <Skeleton sx={{ minWidth: "75px" }} /> :
                        <Typography variant="h4" color="#497B46">{`${currentTeam.name}`}</Typography>
                }
                <Button className="edit-project-name-btn" onClick={() => setIsEditingName(true)}>
                    <EditOutlinedIcon />
                </Button>
            </Box>
        );
    }

    const LoadingScreen = () => {
        return (
            <Box className="test-dashboard-loading">
                <Typography variant="h4" color="#497B46">Loading dashboard...</Typography>
            </Box>
        );
    }

    const NoTestsScreen = () => {
        return (
            <>
                <Page
                    className="test-dashboard-container"
                    titleElement={<TitleElement />}
                    subheader="Create new tests and review the performance of existing tests."
                >
                    <Box className="no-tests-container">
                        <RocketLaunchOutlinedIcon className="rocket-icon" />
                        <Typography
                            className="no-tests-title"
                            variant="h6"
                            color="primary"
                            align="center"
                        >
                            Let's get started!
                        </Typography>
                        <Typography
                            className="no-tests-subtitle-1"
                            variant="body1"
                            color="text.primary"
                            align="center"
                        >
                            Use our in-browser code editor to create your own tests and run them up to every minute.
                        </Typography>
                        <Typography
                            className="no-tests-subtitle-2"
                            variant="body1"
                            color="text.primary"
                            align="center"
                        >
                            You can also request our team create your tests.
                        </Typography>
                        <Box className="no-tests-cta">
                            <a href="https://airtable.com/shrIzrQxUZqBOKabG" target="blank" rel="noreferrer">
                                <Button className="request-button" variant="outlined" color="primary">
                                    <Typography variant="body1">Request Custom Test</Typography>
                                </Button>
                            </a>
                            <Link to={`/${currentTeam.id}/create-test`}>
                                <Button variant="contained" color="secondary">
                                    <Typography variant="body1">Create Test</Typography>
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Page>
                <EditProjectNameModal
                    isOpen={isEditingName}
                    handleClose={() => setIsEditingName(false)}
                    currentName={`${currentTeam.name}`}
                    currentTeam={currentTeam}
                />
            </>
        );
    }

    const TestsScreen = () => {
        return (
            <>
                <Page
                    className="test-dashboard-container"
                    titleElement={<TitleElement />}
                    subheader="Create new tests and review the performance of existing tests."
                    button={<CtaButton />}
                >
                    <Box className="status-cards">
                        <DataCard title="Passing Checks" value={passingTestCount.toString()} isLoading={areCardsLoading} color="primary" />
                        <DataCard title="Failing Checks" value={failingTestCount.toString()} isLoading={areCardsLoading} color="error" />
                        <DataCard title="7D Check Runs" value={weeklyTestRuns.toString()} isLoading={areCardsLoading} color="primary" />
                    </Box>
                    <Box className="search">
                        <SearchBar />
                    </Box>
                    <TestList currentTeam={currentTeam} />
                </Page>
                <EditProjectNameModal
                    isOpen={isEditingName}
                    handleClose={() => setIsEditingName(false)}
                    currentName={`${currentTeam.name}`}
                    currentTeam={currentTeam}
                />
            </>
        );
    }

    return (
        isTestsDataLoading ? <LoadingScreen /> : !tests || tests.length === 0 ? <NoTestsScreen /> : <TestsScreen />
    );
}