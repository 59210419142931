import "./TitledTextEntry.scss";

import { Box, OutlinedInput, OutlinedInputProps, Typography } from "@mui/material";
import { useState } from "react";

interface TitledTextEntryProps extends OutlinedInputProps {
    title: string;
    placeholder?: string;
    startingValue?: string;
}

export const TitledTextEntry = ({ title, placeholder = "", startingValue = "", ...props }: TitledTextEntryProps) => {
    return (
        <Box className="titled-text-entry">
            <Typography className="titled-text-entry-title" variant="body1" color="text.secondary">
                {title}
            </Typography>
            <OutlinedInput
                fullWidth
                className="titled-text-entry-input"
                notched={false}
                placeholder={placeholder}
                {...props}
            />
        </Box>
    );
}