import "./UserTable.scss";

import { Box, Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState } from "react";
import { AlertModal } from "../../components/AlertModal";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Column, Team, Workspace } from "../../types";
import { useDeleteTeamMember, useMembers } from "../../hooks/useMembers";
import { AddUserModal } from "../AddUserModal";
import { useWorkspaces } from "../../hooks/useWorkspaces";

const columns: Column[] = [
    { id: "email", label: "Email" },
    { id: "edit", label: "" },
    { id: "delete", label: "Add User" }
]

interface UserTableProps {
    currentTeam: Team;
}

export const UserTable = ({ currentTeam }: UserTableProps) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { data: users, isLoading: isUsersLoading } = useMembers(currentTeam.id);
    const [selectedUser, setSelectedUser] = useState("");

    const deleteUserMutation = useDeleteTeamMember();

    const handleOpenDeleteModal = (userId: string) => {
        setSelectedUser(userId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteUser = async () => {
        await deleteUserMutation.mutate({ teamId: currentTeam.id, memberId: selectedUser });
        setSelectedUser("");
        setIsDeleteModalOpen(false);
    };

    const LoadingTable = () => {
        return (
            <TableBody>
                <TableRow hover key="loading">
                    {columns.map(column => {
                        if (column.id === "edit") {
                            return (
                                <TableCell key={column.id} align="right">
                                    <EditOutlinedIcon />
                                </TableCell>
                            );
                        } else if (column.id === "delete") {
                            return (
                                <TableCell key={column.id} align="right">
                                    <Button className="delete-button" onClick={() => setIsDeleteModalOpen(true)}>
                                        <DeleteOutlineOutlinedIcon />
                                    </Button>
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    <Skeleton />
                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
            </TableBody>
        );
    }

    const UsersTableBody = () => {
        return (
            <TableBody>
                {users?.map(user => {
                    return (
                        <TableRow hover key={user.id}>
                            {columns.map(column => {
                                if (column.id === "edit") {
                                    return (
                                        <TableCell key={column.id} align="right" width="100px">
                                            <EditOutlinedIcon />
                                        </TableCell>
                                    );
                                } else if (column.id === "delete") {
                                    return (
                                        <TableCell key={column.id} align="right" width="100px">
                                            <Button className="delete-button" onClick={() => handleOpenDeleteModal(user.id)}>
                                                <DeleteOutlineOutlinedIcon />
                                            </Button>
                                        </TableCell>
                                    );
                                } else {
                                    const value = user[column.id as keyof typeof user];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {value}
                                        </TableCell>
                                    );
                                }
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    }

    return (
        <Box className="user-table-box">
            <Typography variant="h4">Team Users</Typography>
            <TableContainer className="user-table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => {
                                if (column.id === "delete") {
                                    return (
                                        <TableCell key={column.id} align="right">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => setIsAddModalOpen(true)}
                                            >
                                                <Typography variant="body1">
                                                    {column.label}
                                                </Typography>
                                            </Button>
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    {
                        isUsersLoading ?
                            <LoadingTable /> :
                            <UsersTableBody />
                    }
                </Table>
            </TableContainer>
            <AddUserModal
                isOpen={isAddModalOpen}
                handleClose={() => setIsAddModalOpen(false)}
                currentTeam={currentTeam}
            />
            <AlertModal
                isOpen={isDeleteModalOpen}
                handleClose={() => setIsDeleteModalOpen(false)}
                handleSubmit={handleDeleteUser}
                icon={ErrorOutlineIcon}
                header="Delete user"
                copy="Are you sure you want to delete this user? This action cannot be undone."
                confirmationText="Delete"
                color="#D92D20"
                hoverColor="#AD1C11"
            />
        </Box>
    );
}