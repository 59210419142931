import "./EditProjectNameModal.scss";

import { Box, Button, Modal, Typography } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TitledTextEntry } from "../TitledTextEntry";
import { useState } from "react";
import { Team, Workspace } from "../../types";
import { useUpdateTeam } from "../../hooks/useTeams";

interface EditProjectNameModalProps {
    isOpen: boolean;
    handleClose: () => void;
    currentName: string;
    currentTeam: Team;
}

export const EditProjectNameModal = ({
    isOpen,
    handleClose,
    currentName,
    currentTeam
}: EditProjectNameModalProps) => {
    const [teamName, setTeamName] = useState(currentName);

    const updateTeamNameMutation = useUpdateTeam();

    const handleUpdateTeamName = async () => {
        const teamId = currentTeam.id;

        updateTeamNameMutation.mutate({ teamId: teamId, teamName: teamName });
        handleClose();
    };

    return (
        <Modal className="gui-modal edit-project-name-modal" open={isOpen} onClose={handleClose}>
            <Box className="edit-project-name-modal-container">
                <Box className="edit-project-name-modal-content">
                    <TitledTextEntry
                        title="Edit Workspace Name"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                    />
                </Box>
                <Box className="edit-project-name-modal-cta">
                    <Button
                        variant="outlined"
                        color="primary"
                        className="cancel"
                        onClick={() => {
                            setTeamName(currentName);
                            handleClose();
                        }}
                    >
                        <Typography variant="body1">Cancel</Typography>
                    </Button>
                    <Button className="confirm" onClick={handleUpdateTeamName}>
                        <Typography variant="body1" color="white">Submit</Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
