import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const queryCache = new QueryCache({
    onError: (error, query) => {
        if (error instanceof Error) console.error({ key: query.queryKey, error: error.message });
    },
});

export const queryClient = new QueryClient({
    queryCache,
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchInterval: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 3,
            staleTime: 30000
        },
    },
});

export const ReactQueryProvider = ({ children }: any) => (
    <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>
);