import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Team } from "../types";
import { authorizedDeleteRequest, authorizedGetRequest, authorizedPostRequest, authorizedPutRequest, getApiAccessTokenParams, getApiEndpoint } from "./useFrontendApi";
import { workspacesQueryKey } from "./useWorkspaces";


// POST /teams
export const useCreateTeam = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<Team, Error, string>(
        async (teamName: string) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const requestBody = {
                name: teamName,
                active: true,
            };

            const response = await authorizedPostRequest("teams", requestBody, accessToken);
            return response.data.record as Team;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data) => {
                console.log(data);

                const keysToRefetch = [
                    teamsQueryKey(user)
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        },
    );
};

// GET /teams
export const teamsQueryKey = (user?: User) => ["useTeam", user];
export const useTeams = () => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<Team[], Error>([teamsQueryKey(user)], async () => {
        if (!user) return ([] as Team[]);

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const response = await authorizedGetRequest("teams", accessToken);

        const teams: Team[] = response.data.records.filter((team: Team) => (team.active === true || team.active === undefined));
        return teams;
    });
}

// GET /teams/{teamId}
export const teamQueryKey = (user?: User, teamId?: string) => ["useTeam", user, teamId];
export const useTeam = (teamId: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<Team, Error>([teamQueryKey(user, teamId)], async () => {
        if (teamId === "") return {} as Team;

        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        const response = await authorizedGetRequest(`teams/${teamId}`, accessToken);
        return response.data.record as Team;
    });
}

// PUT /teams/{teamId}
export const useUpdateTeam = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<Team, Error, any>(
        // TODO: Create type for this
        async ({ teamId, teamName }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const currentTeamResponse = await authorizedGetRequest(`teams/${teamId}`, accessToken);
            const currentTeam = currentTeamResponse.data.record as Team;

            const newTeamData = {
                name: teamName,
                createdDate: currentTeam.createdDate,
                createdUser: currentTeam.createdUser,
                active: true
            }

            const response = await authorizedPutRequest(`teams/${teamId}`, newTeamData, accessToken);
            return response.data.record as Team;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data) => {
                console.log(data);

                const keysToRefetch = [
                    teamsQueryKey(user),
                    teamQueryKey(user, data.id),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        },
    );

}

// DELETE /teams/{teamId}
export const useDeleteTeam = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<boolean, Error, string>(
        async (teamId: string) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedDeleteRequest(`teams/${teamId}`, accessToken);
            return response.data.success as boolean;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data) => {
                console.log(data);

                const keysToRefetch = [
                    teamsQueryKey(user)
                ];

                const promises = keysToRefetch.map(key => client.invalidateQueries([key]));
                await Promise.all(promises);
            },
        },
    );
}
