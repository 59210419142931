import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AlertIntegration } from "../types/AlertIntegration";
import { AlertIntegrationInputCreate } from "../types/AlertIntegrationInputCreate";
import { AlertIntegrationInputUpdate } from "../types/AlertIntegrationInputUpdate";
import { authorizedDeleteRequest, authorizedGetRequest, authorizedPostRequest, authorizedPutRequest, getApiAccessTokenParams } from "./useFrontendApi";

// GET /teams/${teamId}/projects/${projectId}/integrations
export const alertIntegrationsQueryKey = (user?: User, teamId?: string, projectId?: string) => ["useAlertIntegrations", user, teamId, projectId];
export const useAlertIntegrations = (teamId: string, projectId: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<AlertIntegration[], Error>([alertIntegrationsQueryKey(user, teamId, projectId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        if (!teamId || !projectId) return [];

        const response = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/integrations`, accessToken);
        const integrations: AlertIntegration[] = response.data.records.filter((integration: AlertIntegration) => integration.active != false);

        return integrations;
    });
}

// GET /teams/${teamId}/projects/${projectId}/integrations/${integrationId}
export const alertIntegrationQueryKey = (user?: User, teamId?: string, projectId?: string, integrationId?: string) => ["useAlertIntegration", user, teamId, projectId, integrationId];
export const useAlertIntegration = (teamId: string, projectId: string, integrationId: string) => {
    const { user, getAccessTokenSilently } = useAuth0();

    return useQuery<AlertIntegration, Error>([alertIntegrationQueryKey(user, teamId, projectId, integrationId)], async () => {
        const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
        if (!accessToken) throw new Error("Unauthorized");

        if (!teamId || !projectId || !integrationId) return {} as AlertIntegration;

        const response = await authorizedGetRequest(`teams/${teamId}/projects/${projectId}/integrations/${integrationId}`, accessToken);
        const integration: AlertIntegration = response.data.record;

        return integration;
    });
}

// POST /teams/${teamId}/projects/${projectId}/integrations
export const useCreateAlertIntegration = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<AlertIntegration, Error, AlertIntegrationInputCreate>(
        async (integration: AlertIntegrationInputCreate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedPostRequest(`teams/${integration.teamId}/projects/${integration.projectId}/integrations`, integration.newAlertIntegration, accessToken);
            return response.data.record;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = alertIntegrationsQueryKey(user, inputs.teamId, inputs.projectId);
                await client.refetchQueries([keyToRefetch]);
            },
        }
    );
}

// PUT /teams/${teamId}/projects/${projectId}/integrations/${integrationId}
export const useUpdateAlertIntegration = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<AlertIntegration, Error, AlertIntegrationInputUpdate>(
        async (integrationUpdate: AlertIntegrationInputUpdate) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            // Get current integration
            const currentIntegration = await authorizedGetRequest(`teams/${integrationUpdate.teamId}/projects/${integrationUpdate.projectId}/integrations/${integrationUpdate.alertIntegrationId}`, accessToken);

            const updatedAlertIntegration = {
                endpoint: integrationUpdate.endpoint,
                type: currentIntegration.data.record.type,
                createdUser: currentIntegration.data.record.createdUser,
                createdDate: currentIntegration.data.record.createdDate,
                active: true
            };

            const response = await authorizedPutRequest(`teams/${integrationUpdate.teamId}/projects/${integrationUpdate.projectId}/integrations/${integrationUpdate.alertIntegrationId}`, updatedAlertIntegration, accessToken);
            return response.data.record as AlertIntegration;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keysToRefetch = [
                    alertIntegrationsQueryKey(user, inputs.teamId, inputs.projectId),
                    alertIntegrationQueryKey(user, inputs.teamId, inputs.projectId, inputs.alertIntegrationId),
                ];

                const promises = keysToRefetch.map(key => client.refetchQueries([key]));
                await Promise.all(promises);
            },
        }
    );
};

// DELETE /teams/${teamId}/projects/${projectId}/integrations/${integrationId}
export const useDeleteAlertIntegration = () => {
    const client = useQueryClient();
    const { user, getAccessTokenSilently } = useAuth0();

    return useMutation<boolean, Error, any>(
        async ({ teamId, projectId, integrationId }) => {
            const accessToken = await getAccessTokenSilently(getApiAccessTokenParams());
            if (!accessToken) throw new Error("Unauthorized");

            const response = await authorizedDeleteRequest(`teams/${teamId}/projects/${projectId}/integrations/${integrationId}`, accessToken);
            return response.data.success;
        },
        {
            onError: error => console.error(error),
            onSuccess: async (data, inputs) => {
                console.log(data);

                const keyToRefetch = alertIntegrationsQueryKey(user, inputs.teamId, inputs.projectId);
                await client.refetchQueries([keyToRefetch]);
            },
        }
    );
};
