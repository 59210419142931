import { Box, SvgIcon, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

interface SidebarTabProps {
    icon: React.ElementType<any>;
    name: string;
    target: string;
}

export const SidebarTab = ({ icon, name, target }: SidebarTabProps) => {
    const currentLocation = useLocation();
    const match = currentLocation.pathname === target || currentLocation.pathname === `/${target}`;

    return (
        <Box className={`sidebar-tab ${match && `active`}`}>
            <Link to={target} data-testid={target}>
                <Box display="flex" flexDirection="row">
                    <SvgIcon
                        className="sidebar-icon"
                        component={icon}
                    />
                    <Typography variant="h6" color="text.secondary">{name}</Typography>
                </Box>
            </Link>
        </Box>
    );
}

export const ExternalSidebarTab = ({ icon, name, target }: SidebarTabProps) => {
    return (
        <Box className="sidebar-tab">
            <a href={target} target="_blank" rel="noreferrer">
                <Box display="flex" flexDirection="row">
                    <SvgIcon
                        className="sidebar-icon"
                        component={icon}
                    />
                    <Typography variant="h6" color="text.secondary">{name}</Typography>
                </Box>
            </a>
        </Box>
    );
}
