import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DonutChartProps {
    className: string;
    passingRate: number;
}

export const DonutChart = ({ className, passingRate }: DonutChartProps) => {
    const chartData = {
        labels: ["Success", "Failure"],
        datasets: [
            {
                data: [passingRate, 100 - passingRate],
                fill: false,
                backgroundColor: [
                    "rgba(73, 123, 70, 1)",
                    "rgba(217, 45, 32, 1)"
                ],
                borderColor: [
                    "rgba(73, 123, 70, 1)",
                    "rgba(138, 73, 86, 1)"
                ]
            }
        ]
    };

    return (
        <Doughnut
            className={className}
            data={chartData}
        />
    );
}